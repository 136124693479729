import { Flex } from '@chakra-ui/react';
import Filter from '@src/modules/tce/src/components/pages/traffic-count-estimates/components/wizard/Filter';
import { Step2Filter } from '@tce/components/pages/traffic-count-estimates/components/wizard/step2/Step2Filter';
import { agEditableColdef } from '@tce/components/pages/traffic-count-estimates/components/wizard/table';
import TceGrid from '@tce/components/pages/traffic-count-estimates/components/wizard/TceGrid';

const screenName = 'step2';

function DataTable() {
  const columnDefs = [
    { field: 'roadId', headerName: 'Road ID', width: 100 },
    { field: 'name', headerName: 'Road Name', width: 200 },
    { field: 'cwayNo', headerName: 'Cway Number', width: 120 },
    { field: 'cwayStart', headerName: 'Cway Start (m)', width: 120 },
    { field: 'cwayMid', headerName: 'Cway Mid (m)', width: 100 },
    { field: 'cwayEnd', headerName: 'Cway End (m)', width: 120 },
    { ...agEditableColdef('aadtEstimate', 'updateAadtEstimate'), headerName: 'AADT Estimate', width: 100 },
    { field: 'category', headerName: 'ONRC Category' },
    { field: 'trafficGroup', headerName: 'Traffic Group' },
    { field: 'hierarchy', headerName: 'Cway Hierarchy' },
    { field: 'urbanRural', width: 70, headerName: 'Urban Rural' },
  ];

  return (
    <TceGrid
      columnDefs={columnDefs}
      isLoading={false}
      name={screenName}
    />
  );
}

export default function Step2() {
  return (
    <Flex direction="column" width="100%" flexGrow="1">
      <Filter name={screenName}>
        <Step2Filter name={screenName} />
      </Filter>
      <DataTable />
    </Flex>
  );
}
