import {
  Box,
  Text,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  CloseButton,
  Table, Thead, Tbody, Tr, Th, Td,
  Tfoot,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { FiCheck, FiX } from 'react-icons/fi';
import ResultCard from '../../shared/components/ResultCard';
import { calculatePercentage, formatTimeAsHumanReadable } from '../../shared/helpers';

export default function PotholesCard({ item, onEdit }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const totalIdentified = item?.categories?.reduce((total, category) => total + (category.numberIdentified || 0), 0);
  const totalIdentifiedByRca = totalIdentified - (item?.identifiedByRoadUsers || 0);

  return (
    <ResultCard item={item} onEdit={onEdit}>
      <Box>
        <Flex justify="space-between" align="flex-end">
          <Text fontSize="xs">No. Identified by Road Users</Text>
          <Text fontSize="sm" fontWeight="bold" whiteSpace="nowrap">
            {item.identifiedByRoadUsers ? `${item.identifiedByRoadUsers}` : <Text fontSize="sm" color="grey.500">–</Text>}
          </Text>
        </Flex>
        <Flex justify="space-between" align="flex-end">
          <Text fontSize="xs">Average Time to Create Work Order</Text>
          <Text fontSize="sm" fontWeight="bold" whiteSpace="nowrap">
            {item.workOrderCreationTime ? `${formatTimeAsHumanReadable(item.workOrderCreationTime)}` : <Text fontSize="sm" color="grey.500">–</Text>}
          </Text>
        </Flex>
        <Flex justify="space-between" align="flex-end">
          <Text fontSize="xs">No. Identified by RCA/Contractors</Text>
          <Text fontSize="sm" fontWeight="bold" whiteSpace="nowrap">
            <Text fontSize="sm" color={totalIdentifiedByRca < 0 ? 'red.600' : 'grey.500'}>{totalIdentifiedByRca}</Text>
          </Text>
        </Flex>
        <Flex justify="space-between" align="flex-end">
          <Text fontSize="xs">Confidence in % Completed in LoS Timeframe</Text>
          <Text fontSize="sm" fontWeight="bold" whiteSpace="nowrap">
            {item.losAchievedConfidence ? `${item.losAchievedConfidence} / 5` : <Text fontSize="sm" color="grey.500">–</Text>}
          </Text>
        </Flex>
        <Flex justify="space-between" align="flex-end">
          <Text fontSize="xs">Confidence in % Completed in 24hrs</Text>
          <Text fontSize="sm" fontWeight="bold" whiteSpace="nowrap">
            {item.completedIn24HrsConfidence ? `${item.completedIn24HrsConfidence} / 5` : <Text fontSize="sm" color="grey.500">–</Text>}
          </Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text fontSize="xs">Completed entering the Pothole results</Text>
          <Icon as={item.userIndicatedCompletion ? FiCheck : FiX} color={item.userIndicatedCompletion ? 'green.500' : 'gray.500'} boxSize={4} />
        </Flex>
        <Button mt={3} size="xs" colorScheme="gray" onClick={onOpen}>
          View Categories
        </Button>
      </Box>

      {/* Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader position="relative">
            All Identified Potholes by ONRC Categories
            <CloseButton position="absolute" right="4" top="4" onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            {item.categories?.length ? (
              <Table variant="simple" size="sm" width="full">
                <Thead>
                  <Tr>
                    <Th fontSize="xs">Category</Th>
                    <Th fontSize="xs">No. Identified</Th>
                    <Th fontSize="xs">LoS Timeframe</Th>
                    <Th fontSize="xs">LoS Target %</Th>
                    <Th fontSize="xs">Repaired in LoS Timeframe</Th>
                    <Th fontSize="xs">Repaired in 24hrs</Th>
                    <Th fontSize="xs">Not Repaired in the Quarter</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {item.categories.map((category) => (
                    <Tr key={category.categoryId}>
                      <Td>{category.categoryDescription}</Td>
                      <Td fontWeight="bold">{category.numberIdentified ?? <Text fontSize="sm" color="grey.300" fontWeight="normal">–</Text>}</Td>
                      <Td>{category.losTimeframe || <Text fontSize="sm" color="grey.300">–</Text>}</Td>
                      <Td>{category.losTargetPercentage ? `${category.losTargetPercentage}%` : <Text fontSize="sm" color="grey.300">–</Text>}</Td>
                      <Td>
                        <HStack>
                          {category.completedWithinLos ? <Text fontWeight="bold" minWidth="30%">{category.completedWithinLos}</Text> : <Text fontSize="sm" color="grey.300">–</Text>}
                          {category.completedWithinLos ? calculatePercentage(category.numberIdentified, category.completedWithinLos) : null}
                        </HStack>
                      </Td>
                      <Td>
                        <HStack>
                          {category.completedWithin24Hrs ? <Text fontWeight="bold" minWidth="30%">{category.completedWithin24Hrs}</Text> : <Text fontSize="sm" color="grey.300">–</Text>}
                          {category.completedWithin24Hrs ? calculatePercentage(category.numberIdentified, category.completedWithin24Hrs) : null}
                        </HStack>
                      </Td>
                      <Td>
                        <HStack>
                          {category.notRepairedInQuarter ? <Text fontWeight="bold" minWidth="30%">{category.notRepairedInQuarter}</Text> : <Text fontSize="sm" color="grey.300">–</Text>}
                          {category.notRepairedInQuarter ? calculatePercentage(category.numberIdentified, category.notRepairedInQuarter) : null}
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tfoot>
                  <Tr borderTopWidth="2px" borderBottomWidth="2px">
                    <Td fontWeight="bold">Total</Td>
                    <Td fontWeight="bold">
                      {totalIdentified}
                    </Td>
                    <Td />
                    <Td />
                    <Td>
                      <HStack>
                        <Text fontWeight="bold" minWidth="30%">{item.categories.reduce((total, category) => total + (category.completedWithinLos || 0), 0)}</Text>
                        {calculatePercentage(item.categories.reduce((total, category) => total + (category.numberIdentified || 0), 0), item.categories.reduce((total, category) => total + (category.completedWithinLos || 0), 0))}
                      </HStack>
                    </Td>
                    <Td>
                      <HStack>
                        <Text fontWeight="bold" minWidth="30%">{item.categories.reduce((total, category) => total + (category.completedWithin24Hrs || 0), 0)}</Text>
                        {calculatePercentage(item.categories.reduce((total, category) => total + (category.numberIdentified || 0), 0), item.categories.reduce((total, category) => total + (category.completedWithin24Hrs || 0), 0))}
                      </HStack>
                    </Td>
                    <Td>
                      <HStack>
                        <Text fontWeight="bold" minWidth="30%">{item.categories.reduce((total, category) => total + (category.notRepairedInQuarter || 0), 0)}</Text>
                        {calculatePercentage(item.categories.reduce((total, category) => total + (category.numberIdentified || 0), 0), item.categories.reduce((total, category) => total + (category.notRepairedInQuarter || 0), 0))}
                      </HStack>
                    </Td>
                  </Tr>
                </Tfoot>
              </Table>
            ) : (
              <Text fontSize="sm" color="gray.500">
                No category data available.
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} size="sm">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ResultCard>
  );
}
