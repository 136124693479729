import MainLayout from '@src/components/layouts/main/MainLayout';
import TtmResults from './TtmResults';
import MainNav from '../shared/components/MainNav';

export default function TemporaryTrafficManagement() {
  return (
    <MainLayout>
      <MainNav />
      <TtmResults />
    </MainLayout>
  );
}
