import MainLayout from '@src/components/layouts/main/MainLayout';
import PotholesResults from './PotholesResults';
import MainNav from '../shared/components/MainNav';

export default function TemporaryTrafficManagement() {
  return (
    <MainLayout>
      <MainNav />
      <PotholesResults />
    </MainLayout>
  );
}
