import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
  useTheme,
  Text,
  GridItem,
  Grid,
  SimpleGrid,
} from '@chakra-ui/react';
import { groupBy } from 'lodash';
import Chart from 'react-apexcharts';
import {
  roundToDecimal, convertBubbleRange,
} from '@src/utils/math';
import { getContrastColor, RAG_COLORS } from '@src/utils/color';
import { useFilteredCategories } from '../../hooks/useFilteredCategories';
import ReportSubheading from '../shared/ReportSubheading';
import CollapsedContent from '../shared/CollapsedContent';
import ChartContainer from '../shared/ChartContainer';

const {
  red, orange, yellow, yellowGreen, green,
} = RAG_COLORS;

const colourMap = {
  High: red,
  'Medium High': orange,
  Medium: yellow,
  'Low Medium': yellowGreen,
  Low: green,
};

export default function Hsp5({
  filters, selectedToggleOption, reportData, networkSelector,
}) {
  const theme = useTheme();
  const filteredCategories = useFilteredCategories(filters);
  const years = [...new Set(reportData.data.map((x) => x.latestYear))].sort();
  const rca = reportData.rcas[0];
  const filteredReportData = reportData.data.filter((x) => Object.entries(selectedToggleOption).every(([k, v]) => v.includes(x[k])));
  const selectedRcaData = filteredReportData.filter((x) => x.id === rca.id);

  const bandTotals = Object.entries(groupBy(selectedRcaData, 'irrBand'))
    .map(([k, v]) => ({
      name: k,
      total: roundToDecimal(v.map((item) => item.lengthPerc).reduce((a, b) => a + b, 0), 2),
    }));
  const transformedDataByBand = Object.entries(groupBy(selectedRcaData.filter((x) => x.latestYear === years.at(-1)), 'irrBand'))
    .map(([k, v]) => ({
      irrBand: k,
      data: v.map((item) => ({ x: item.category, y: roundToDecimal(item.lengthPerc, 3), fillColor: colourMap[item.irrBand] })),
    }));
  const bubbleChartData = Object.entries(groupBy(filteredReportData.filter((x) => x.latestYear === years.at(-1)), 'category'))
    .map(([k, v]) => ({
      category: k,
      length: v[0].length_m / 1000,
      crashCount: v[0].crashCount,
      totalPerc: roundToDecimal(v.map((item) => item.lengthPerc).reduce((a, b) => a + b, 0), 2),
    }));
  const transformedDataByCategory = Object.entries(groupBy(selectedRcaData.filter((x) => x.latestYear === years.at(-1)), 'category'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({ x: item.irrBand, y: roundToDecimal(item.lengthPerc, 3), fillColor: colourMap[item.irrBand] })),
    }));
  const comparisonData = Object.entries(groupBy(filteredReportData.filter((x) => x.latestYear === years.at(-1)), 'category'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        rcaName: item.rcaName,
        irrBand: item.irrBand,
        lengthPerc: roundToDecimal(item.lengthPerc, 3),
      })),
    }))
    .map((x) => ({
      name: x.name,
      data: Object.entries(groupBy(x.data, 'irrBand'))
        .map(([k, v]) => ({
          name: k,
          data: v.map((item) => ({
            x: item.rcaName,
            y: roundToDecimal(item.lengthPerc, 3),
            fillColor: colourMap[item.irrBand],
          })),
        })),
    }));
  const filteredTotals = Object.entries(groupBy(filteredReportData.filter((x) => x.latestYear === years.at(-1)), 'category'))
    .map(([k, v]) => ({
      category: k,
      data: v.map((item) => ({
        rcaName: item.rcaName,
        irrBand: item.irrBand,
        lengthPerc: roundToDecimal(item.lengthPerc, 3),
      })),
    }))
    .map((x) => ({
      name: x.category,
      data: Object.entries(groupBy(x.data, 'rcaName'))
        .map(([k, v]) => ({
          rcaName: k,
          total: roundToDecimal(v.map(({ lengthPerc }) => lengthPerc).reduce((a, b) => a + b, 0), 2),
        })),
    }));
  const summaryData = Object.entries(groupBy(reportData.data.filter((x) => x.latestYear === years.at(-1)), 'category'))
    .map(([k, v]) => ({
      category: k,
      data: v.map((item) => ({
        rcaName: item.rcaName,
        irrBand: item.irrBand,
        lengthPerc: roundToDecimal(item.lengthPerc, 3),
      })),
    }))
    .map((x) => ({
      name: x.category,
      data: Object.entries(groupBy(x.data, 'rcaName'))
        .map(([k, v]) => ({
          rcaName: k,
          data: v.map((item) => ({
            rcaName: item.rcaName,
            irrBand: item.irrBand,
            lengthPerc: roundToDecimal(item.lengthPerc, 3),
            total: roundToDecimal(v.map(({ lengthPerc }) => lengthPerc).reduce((a, b) => a + b, 0), 2),
          })),
        })),
    }));
  return (
    <>
      <Grid templateColumns={{ md: '100%', lg: '1fr 1fr', xl: '1fr 2fr' }} gap={4}>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>
            Proportion of IRR Bands
          </ReportSubheading>
          <Text mb={8}>This chart shows the proportion (percent) of the chosen Infrastructure Risk Rating (IRR) bands that are in the selected ONF categories. There are five bands: High, Medium High, Medium, Low Medium, and Low.</Text>
          <ChartContainer>
            <Chart
              options={
                {
                  dataLabels: {
                    enabled: true,
                  },
                  colors: [...bandTotals.map((x) => colourMap[x.name])],
                  legend: {
                    show: true,
                    position: 'bottom',
                  },
                  tooltip: {
                    enabled: false,
                    enabledOnSeries: [0],
                    marker: {
                      show: false,
                    },
                  },
                  labels: bandTotals.map((x) => x.name),
                }
              }
              series={bandTotals.map((x) => x.total)}
              type="pie"
              width="100%"
            />
          </ChartContainer>
        </GridItem>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>
            Percentage of bands per Street Category
          </ReportSubheading>
          <Text mb={4}>
            This chart shows the cumulative proportions (percent) of the IRR bands for each ONF Street Category. Choose one or more bands to see the combined percent of the band/s per Street Category.
            <Text color="red" mb={8} as="span">
              The data used in this page is sourced from Megamaps and is potentially out of date. Please treat all results on this page as indicative only.
            </Text>
          </Text>

          <ChartContainer>
            <Chart
              options={
                {
                  chart: {
                    stacked: true,
                  },
                  xaxis: {
                    labels: {
                      style: {
                        colors: filteredCategories.map((x) => theme.colors.onfCategory[x.description].base),
                        fontWeight: 'bold',
                      },
                    },
                    tickPlacement: 'between',
                  },
                  yaxis: [
                    {
                      title: {
                        text: 'Percentage of Network Length',
                      },
                      labels: {
                        formatter(val) {
                          return `${val ? val.toFixed(2) : val}%`;
                        },
                      },
                    },
                  ],
                  dataLabels: {
                    enabled: false,
                  },
                  legend: {
                    show: true,
                    customLegendItems: [...transformedDataByBand.map((x) => x.irrBand)],
                    markers: {
                      fillColors: [...transformedDataByBand.map((x) => colourMap[x.irrBand])],
                    },
                    labels: {
                      useSeriesColors: false,
                    },
                  },
                  tooltip: {
                    enabled: true,
                    marker: {
                      show: false,
                    },
                  },
                  plotOptions: {
                    bar: {
                      distributed: true,
                      horizontal: false,
                    },
                  },
                }
              }
              series={
                transformedDataByBand.map((x) => ({
                  name: x.irrBand, data: x.data,
                }))
              }
              type="bar"
              width="100%"
              height="400"
            />
          </ChartContainer>
        </GridItem>
      </Grid>
      <Box my={8}>
        <ReportSubheading>
          Proportion of Bands and 10-year crash counts
        </ReportSubheading>
        <Grid templateColumns={{ md: '100%', lg: '1fr 3fr', xl: '1fr 4fr' }} gap={4}>
          <GridItem>
            <Text mb={8}>
              This chart shows the proportion of the chosen bands per ONF Street Category compared to the 10-year crash count involving deaths and serious injuries (as reported in the Personal/Collective Risk measure). The x-axis is the network length of each ONF Street Category. The y-axis is the percentage of the chosen bands for the category. The bubble size shows the number of crashes involving deaths and serious injuries in the last ten years.
            </Text>
          </GridItem>
          <GridItem>
            <ChartContainer minHeight="550">
              <Chart
                options={
              {
                colors: bubbleChartData.map((x) => theme.colors.onfCategory[x.category].base),
                grid: {
                  padding: {
                    left: 20,
                    bottom: 50,
                  },
                },
                legend: {
                  offsetY: -20,
                },
                xaxis: {
                  type: 'numeric',
                  title: {
                    text: 'Street Category Network Length (km)',
                    offsetY: 10,
                  },
                  min: 0,
                  max: Math.max(...bubbleChartData.map((x) => x.length)) * 1.1,
                  labels: {
                    formatter(val) {
                      return `${val.toFixed(0)} km`;
                    },
                  },
                },
                yaxis: [
                  {
                    min: 0,
                    max: (Math.max(...bubbleChartData.map((x) => x.totalPerc)) + 0.5) * 1.1,
                    title: {
                      text: 'Percentage of Network Length',
                    },
                    labels: {
                      formatter(val) {
                        return `${val.toFixed(2)}%`;
                      },
                    },
                  },
                ],
                dataLabels: {
                  enabled: false,
                },
                fill: {
                  opacity: 0.8,
                },
                tooltip: {
                  marker: {
                    show: false,
                  },
                  z: {
                    title: 'Length Percentage:',
                  },
                  y: {
                    title: {
                      formatter: () => 'Crash Count:',
                    },
                  },
                  x: {
                    show: true,
                    formatter: (val, { seriesIndex, w }) => `${w.globals.seriesNames[seriesIndex]}: ${val.toFixed(0)} km`,
                  },
                  custom({ seriesIndex, dataPointIndex, w }) {
                    const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                    const { name } = w.globals.initialSeries[seriesIndex];

                    return `<div style="padding: 10px">
                    <p style="color: ${theme.colors.onfCategory[name].base}; font-weight: bold; margin-bottom: 10px;">${name}</p> 
                    <ul>
                    <li><b>Crash Count</b>: ${data.crashCount}</li>
                    <li><b>Length Percentage</b>: ${data.y}%</li>
                    </ul></div>`;
                  },
                },
                plotOptions: {
                  bubble: {
                    zScaling: true,
                  },
                },
              }
            }
                series={bubbleChartData.map((item) => ({
                  name: item.category,
                  data: [
                    {
                      x: item.length,
                      y: item.totalPerc,
                      z: convertBubbleRange(item.crashCount, [Math.min(...bubbleChartData.map((x) => x.crashCount)), Math.max(...bubbleChartData.map((x) => x.crashCount))], [1, 8]),
                      crashCount: item.crashCount,
                    }],
                }))}
                type="bubble"
                width="100%"
                height="100%"
              />
            </ChartContainer>
          </GridItem>
        </Grid>
      </Box>
      <Box my={8}>
        <ReportSubheading>Bands per Category</ReportSubheading>
        <Text mb={8}>These charts show the percentage of each band that is assigned to the selected ONF Street Categories. Choose the band/s you wish to see.</Text>
        <SimpleGrid columns={[2, 2, 4, 4, 6, 6]} spacing={2}>
          {transformedDataByCategory.map((cat) => (
            <GridItem
              as={ChartContainer}
              key={`cat_${cat.name}`}
              p={2}
              minH="280"
            >
              <Chart
                options={
                  {
                    chart: {
                      stacked: true,
                    },
                    title: {
                      text: cat.name,
                      style: {
                        color: theme.colors.onfCategory[cat.name].base,
                      },
                    },
                    yaxis: [
                      {
                        min: 0,
                        max: Math.max(...transformedDataByCategory.flatMap((x) => x.data.map(({ y }) => y))),
                        forceNiceScale: true,
                        labels: {
                          formatter(val) {
                            return `${val.toFixed(2)}%`;
                          },
                        },
                      },
                    ],
                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      show: true,
                    },
                    tooltip: {
                      enabled: true,
                      marker: {
                        show: false,
                      },
                    },
                  }
                }
                series={
                  [
                    {
                      name: cat.name,
                      data: [...cat.data],
                    },
                  ]
                }
                type="bar"
                width="100%"
                height="100%"
              />
            </GridItem>
          ))}
        </SimpleGrid>
      </Box>
      {networkSelector}
      <Box my={8}>
        <ReportSubheading>Comparison to other networks</ReportSubheading>
        <Text mb={8}>
          Select other networks to compare with. No IRR band data was available for some RCAs so any Region, Peer Group or National summaries may not be complete and are indicative only.
        </Text>
        <SimpleGrid columns={[2, 2, 4, 4, 6, 6]} spacing={2}>
          {comparisonData.map((cat) => (
            <GridItem
              as={ChartContainer}
              key={`cat_${cat.name}`}
              p={2}
              minH="280"
            >
              <Chart
                options={
                  {
                    title: {
                      text: cat.name,
                      style: {
                        color: theme.colors.onfCategory[cat.name].base,
                      },
                    },
                    chart: {
                      stacked: true,
                    },
                    yaxis: [
                      {
                        min: 0,
                        max: Math.max(...filteredTotals.flatMap((x) => x.data.flatMap(({ total }) => total))),
                        forceNiceScale: true,
                        labels: {
                          formatter(val) {
                            return `${val.toFixed(2)}%`;
                          },
                        },
                      },
                    ],
                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      show: false,
                      markers: {
                        fillColors: [red, orange, yellow, yellowGreen, green],
                      },
                    },
                    tooltip: {
                      enabled: true,
                      shared: true,
                      intersect: false,
                      marker: {
                        show: false,
                      },
                    },
                  }
                }
                series={
                  cat.data.map(({ name, data }) => ({ name, data: data.map((x) => x) }))
                }
                type="bar"
                width="100%"
                height="100%"
              />
            </GridItem>
          ))}
        </SimpleGrid>
      </Box>
      <CollapsedContent collapsedLabel="View Data Table" openLabel="Hide Data Table">
        <Box border="1px solid" borderColor="gray.100" my={4}>
          <TableContainer>
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th position="sticky" left="0" background="gray.50" py={4} whiteSpace="nowrap" width="1px">
                    Category
                  </Th>
                  <Th background="gray.50">Network</Th>
                  <Th background="gray.50">High</Th>
                  <Th background="gray.50">Medium-High</Th>
                  <Th background="gray.50">Medium</Th>
                  <Th background="gray.50">Low-Medium</Th>
                  <Th background="gray.50">Low</Th>
                </Tr>
              </Thead>
              <Tbody>
                {summaryData.map((category, idx1) => category.data.map(({ rcaName, data }, idx2) => (
                  <Tr key={`row_${idx1}_${idx2}`}>
                    {idx2 === 0
                      && (
                      <Td
                        position="sticky"
                        left="0"
                        color={getContrastColor(theme.colors.onfCategory[category.name].base)}
                        background={theme.colors.onfCategory[category.name].base}
                        rowSpan={category.data.length * years.length}
                        borderBottom="2px solid"
                        borderColor={idx2 === 0 ? theme.colors.onfCategory[category.name].base : 'gray.100'}
                        fontWeight="bold"
                      >
                        {category.name}
                      </Td>
                      )}
                    <Td
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {rcaName}
                    </Td>
                    {data.map(({ irrBand, lengthPerc }) => (
                      <Td
                        key={`cat_${idx2}_${irrBand}`}
                        borderBottom="2px solid"
                        borderColor="gray.100"
                      >
                        {lengthPerc}
                        %
                      </Td>
                    ))}
                  </Tr>
                )))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </CollapsedContent>
    </>
  );
}
