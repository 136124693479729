import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToast } from '@chakra-ui/react';
import useQuarterlyReportingApi from '../api/useQuarterlyReportingApi';

export function useQuarterlyReportOptions() {
  const api = useQuarterlyReportingApi();

  return useQuery(
    ['quarterly-reporting-report-options'],
    async () => api.getQuarterlyReportOptions(),
    {
      cacheTime: 3600,
    },
  );
}

export function useQuarterlyReport(lockYear, quarter) {
  const api = useQuarterlyReportingApi();
  const convertedLockYear = lockYear?.replace('/', '-');

  return useQuery(
    ['quarterly-reporting-report', lockYear, quarter],
    async () => api.getQuarterlyReport(convertedLockYear, quarter),
    {
      enabled: Boolean(convertedLockYear && quarter),
      cacheTime: 3600,
    },
  );
}

export const useActivateQuarterlyResult = () => {
  const api = useQuarterlyReportingApi();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    ({ quarterNumber, lockYear, isTestMode }) => api.activateQuarterlyResults({ quarterNumber, lockYear, isTestMode }).then((result) => result),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('temporary-traffic-management');
        queryClient.invalidateQueries('temporary-traffic-management-kpi');
        queryClient.invalidateQueries('quarterly-reporting-report');
        toast({
          title: 'Success',
          description: 'Results were successfully activated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.response.data.Message ? error.response.data.Message : 'Results could not be activated. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );
};

export const useDeactivateQuarterlyResult = () => {
  const api = useQuarterlyReportingApi();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    ({ quarterNumber, lockYear }) => api.deactivateQuarterlyResults({ quarterNumber, lockYear }).then((result) => result),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('temporary-traffic-management');
        queryClient.invalidateQueries('temporary-traffic-management-kpi');
        queryClient.invalidateQueries('quarterly-reporting-report');
        toast({
          title: 'Success',
          description: 'Results were successfully deactivated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.response.data.Message ? error.response.data.Message : 'Results could not be deactivated. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );
};
