import {
  Flex,
  Box,
  Text,
} from '@chakra-ui/react';
import { roundKpiValue } from '@src/modules/performance/shared/helpers';
import ResultCard from '../../shared/components/ResultCard';

export default function TtmCard({ item, onEdit }) {
  return (
    <ResultCard item={item} onEdit={onEdit}>
      <Box>
        <Text fontSize="xs" mb={4}>Costs are year to date.  Site counts are for the quarter.</Text>
        <Flex justify="space-between" align="center">
          <Text fontSize="xs">NLTP YTD Cost ($000&apos;s)</Text>
          <Text fontSize="sm" fontWeight="bold">
            {item.nltpCost !== null ? `$${item.nltpCost?.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 4,
            })}` : '-'}
          </Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text fontSize="xs">TTM YTD Cost ($000&apos;s)</Text>
          <Text fontSize="sm" fontWeight="bold">
            {item.ttmCost !== null ? `$${item.ttmCost?.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 4,
            })}` : '-'}
          </Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text fontSize="xs">TTM Cost %</Text>
          <Text fontSize="sm" fontWeight="bold">
            {item.ttmPercentage !== null ? `${roundKpiValue(item.ttmPercentage, 5)}%` : '-'}
          </Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text fontSize="xs">Confidence in TTM Cost</Text>
          <Text fontSize="sm" fontWeight="bold">{item.ttmCostConfidence ? `${item.ttmCostConfidence} / 5` : '-'}</Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text fontSize="xs">Total Site Inspections for the quarter</Text>
          <Text fontSize="sm" fontWeight="bold">{item.completedSiteInspections}</Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text fontSize="xs">Redundant Sites for the quarter</Text>
          <Text fontSize="sm" fontWeight="bold">{item.unattendedSiteInspections}</Text>
        </Flex>
      </Box>
    </ResultCard>
  );
}
