import {
  Box,
  useTheme,
  Grid,
  GridItem,
  SimpleGrid,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import { groupBy } from 'lodash';

import { getContrastColor, COMPARISON_COLORS, getTrendlineColor } from '@src/utils/color';
import {
  getRangeOfPoints, convertBubbleRange, roundToDecimal, getTrendDirection,
} from '@src/utils/math';
import { useTrendSvg } from '../../hooks/useTrendSvg';
import ChartContainer from '../shared/ChartContainer';
import ReportSubheading from '../shared/ReportSubheading';
import { categories } from '../../api/categories';
import CollapsedContent from '../shared/CollapsedContent';

export default function Ia1({
  filters, selectedToggleOption, reportData, networkSelector,
}) {
  const theme = useTheme();
  const { upGreenSvg, downRedSvg, flatSvg } = useTrendSvg();
  const filteredCategories = categories.filter((x) => filters.categories.indexOf(x.description) > -1);
  const years = [...new Set(reportData.data.map((x) => x.latestYear))].sort();
  const rca = reportData.rcas[0];
  const filteredReportData = reportData.data.filter((x) => Object.entries(selectedToggleOption).every(([k, v]) => v.includes(x[k])));
  const selectedRcaData = filteredReportData.filter((x) => x.id === rca.id);
  const currentYearData = filteredReportData.filter((x) => x.latestYear === years.at(-1));
  const currentYearOverview = selectedRcaData.filter((x) => x.latestYear === years.at(-1))
    .filter((x) => x.countTotal > 0)
    .map((item) => ({
      category: item.category,
      value: item.countTotal,
      vkt: roundToDecimal(item.vkt, 1),
      trendM: item.trendM,
      length: roundToDecimal(item.length_km, 1),
      trendY1: item.trendB,
      trendY2: item.trendB + (item.trendM * 5),
      trendDirection: getTrendDirection(roundToDecimal(item.trendB, 2), roundToDecimal(item.trendM, 2), 5),
    }));
  const bubbleChart = selectedRcaData.filter((x) => x.latestYear === years.at(-1))
    .filter((x) => x.countTotal > 0)
    .map((item) => ({
      category: item.category,
      count: item.countTotal,
      vkt: roundToDecimal(item.vkt, 1),
      length: roundToDecimal(item.length_km, 1),
    }));
  const transformedDataByRca = Object.entries(groupBy(currentYearData, 'rcaName'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        x: item.category,
        y: item.countTotal.toFixed(2),
      })),
    }));
  const transformedDataByCategory = Object.entries(groupBy(selectedRcaData, 'category'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        year: item.latestYear,
        value: item.countTotal,
        trendY1: item.trendB,
        trendY2: item.trendB + (item.trendM * 5),
      })),
    }));
  const summaryData = Object.entries(groupBy(filteredReportData, 'rcaName'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        year: item.latestYear,
        category: item.category,
        value: item.countTotal,
      })),
    }))
    .map((x) => ({
      name: x.name,
      data: Object.entries(groupBy(x.data, 'year'))
        .map(([k, v]) => ({
          year: k,
          data: v.map((item) => ({
            category: item.category,
            value: roundToDecimal(item.value, 1),
          })),
        })),
    }));

  const reportType = selectedToggleOption?.report_type?.[0];

  return (
    <>
      <Grid templateColumns={{ md: '100%', lg: '1fr 1fr', xl: '2fr 1fr' }} gap={4}>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>The percentage of travel on roads smoother than the threshold</ReportSubheading>
          <Text mb={8}>
            This chart shows the percentage of travel in each ONF Street Category that is smoother than the threshold.
            The graph shows the smooth travel percentage for the selected year. The trend arrow above each bar shows whether the trend is
            improving (green & up) or getting worse (red & down).
          </Text>
          <ChartContainer>
            <Chart
              options={
                {
                  xaxis: {
                    type: 'category',
                    categories: [...new Set(currentYearOverview.map((x) => x.category))],
                    labels: {
                      style: {
                        colors: currentYearOverview.map((x) => theme.colors.onfCategory[x.category].base),
                        fontWeight: 'bold',
                      },
                    },
                    tickPlacement: 'between',
                  },
                  yaxis: [
                    {
                      forceNiceScale: true,
                      title: {
                        text: 'Percentage of travel on smooth roads',
                      },
                      labels: {
                        formatter(val) {
                          return `${val.toFixed(0)}%`;
                        },
                      },
                    },
                  ],
                  dataLabels: {
                    enabled: false,
                  },
                  colors: currentYearOverview.map((x) => theme.colors.onfCategory[x.category].base),
                  legend: {
                    show: false,
                  },
                  tooltip: {
                    enabled: true,
                    enabledOnSeries: [0],
                    marker: {
                      show: false,
                    },
                    custom({ seriesIndex, dataPointIndex, w }) {
                      const {
                        x, y, trend, length, vkt,
                      } = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                      return `<div style="padding: 10px">
                      <p style="color: ${theme.colors.onfCategory[x].base}; font-weight: bold; margin-bottom: 10px;">${x}</p> 
                      <ul>
                      <li><b>Smooth Travel</b>: ${y}%</li>
                      <li><b>Trend</b>: ${trend.toFixed(2)}</li>
                      <li><b>Length</b>: ${length} km</li>
                      <li><b>VKT</b>: ${vkt} mil</li>
                      </ul></div>`;
                    },
                  },
                  plotOptions: {
                    bar: {
                      distributed: true,
                      horizontal: false,
                    },
                  },
                  annotations: {
                    points: currentYearOverview.map((item) => (
                      {
                        x: item.category,
                        y: item.value,
                        marker: {
                          size: 0,
                        },
                        customSVG: {
                          // eslint-disable-next-line no-nested-ternary
                          SVG: item.trendDirection === 'flat' ? flatSvg : item.trendDirection === 'up' ? upGreenSvg : downRedSvg,
                          cssClass: undefined,
                          offsetX: -30,
                          offsetY: -50,
                        },
                      }
                    )),
                  },
                }
              }
              series={
                [
                  {
                    name: years.at(-1),
                    data: currentYearOverview.map((x) => ({
                      y: x.value, x: x.category, trend: x.trendM, length: x.length, vkt: x.vkt,
                    })),
                  },
                ]
              }
              type="bar"
              width="100%"
              height="400"
            />
          </ChartContainer>
        </GridItem>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>VKT % per ONF Street Category</ReportSubheading>
          <Text mb={8}>This chart shows the percentage of VKT per ONF Street Category, in the network.</Text>
          <ChartContainer>
            <Chart
              options={
                {
                  dataLabels: {
                    enabled: true,
                  },
                  colors: currentYearOverview.map((x) => theme.colors.onfCategory[x.category].base),
                  legend: {
                    show: true,
                    position: 'bottom',
                  },
                  tooltip: {
                    enabled: false,
                    enabledOnSeries: [0],
                    marker: {
                      show: false,
                    },
                  },
                  labels: currentYearOverview.map((x) => x.category),
                }
              }
              series={currentYearOverview.map((x) => x.vkt)}
              type="pie"
              width="100%"
            />
          </ChartContainer>
        </GridItem>
        <GridItem display="flex" flexDirection="column" />
      </Grid>
      <Box my={8}>
        <ReportSubheading>The percentage of travel on roads smoother than the threshold for each ONF Street Category</ReportSubheading>
        <Grid templateColumns={{ md: '100%', lg: '1fr 3fr', xl: '1fr 4fr' }} gap={4}>
          <GridItem>
            <Text mb={8}>
              This chart shows the percentage of travel smoother than the threshold,
              for each ONF Street Category. The size of the bubble indicates the vehice journeys (VKT) for each category. The bottom axis is the network length of the Street Categories.
            </Text>
          </GridItem>
          <GridItem>
            <ChartContainer minHeight="550">
              <Chart
                options={
              {
                colors: bubbleChart.map((x) => theme.colors.onfCategory[x.category].base),
                grid: {
                  padding: {
                    left: 20,
                    bottom: 50,
                  },
                },
                legend: {
                  offsetY: -20,
                },
                xaxis: {
                  type: 'numeric',
                  title: {
                    text: 'Street Category Network Length (km)',
                    offsetY: 10,
                  },
                  tickAmount: 10,
                  min: -5,
                  max: Math.round(Math.max(...bubbleChart.map((x) => x.length)) * 1.1),
                  labels: {
                    formatter(val) {
                      if (val < 0) return '0 km';
                      return `${val.toFixed(0)} km`;
                    },
                  },
                },
                yaxis: [
                  {
                    forceNiceScale: true,
                    min: (Math.min(...bubbleChart.map((x) => x.count)) - 0.5) * 1.1,
                    max: (Math.max(...bubbleChart.map((x) => x.count)) + 0.5) * 1.1,
                    title: {
                      text: 'Percentage of travel on smooth roads',
                    },
                    labels: {
                      formatter(val) {
                        return `${val.toFixed(0)}%`;
                      },
                    },
                  },
                ],
                dataLabels: {
                  enabled: false,
                },
                fill: {
                  opacity: 0.8,
                },
                tooltip: {
                  marker: {
                    show: false,
                  },
                  z: {
                    title: 'VKT:',
                  },
                  y: {
                    title: {
                      formatter: () => 'Percentage:',
                    },
                  },
                  x: {
                    show: true,
                    formatter: (val, { seriesIndex, w }) => `${w.globals.seriesNames[seriesIndex]}: ${val.toFixed(0)} km`,
                  },
                  custom({ seriesIndex, dataPointIndex, w }) {
                    const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                    const { name } = w.globals.initialSeries[seriesIndex];

                    return `<div style="padding: 10px">
                    <p style="color: ${theme.colors.onfCategory[name].base}; font-weight: bold; margin-bottom: 10px;">${name}</p> 
                    <ul>
                    <li><b>Smooth Travel</b>: ${data.y}%</li>
                    <li><b>Length</b>: ${data.x} km</li>
                    <li><b>VKT</b>: ${data.vkt} mil</li>
                    </ul></div>`;
                  },
                },
                annotations: {
                  position: 'back',
                  yaxis: [
                    {
                      x: 0,
                      strokeDashArray: 0,
                      borderColor: '#666',
                      borderWidth: 1,
                      opacity: 0.5,
                    },
                  ],
                },
                plotOptions: {
                  bubble: {
                    zScaling: true,
                    minBubbleRadius: 4,
                    maxBubbleRadius: 100,
                  },
                },
              }
            }
                series={bubbleChart.map((item) => ({
                  name: item.category,
                  data: [
                    {
                      x: item.length,
                      y: item.count,
                      z: convertBubbleRange(item.vkt, [Math.min(...bubbleChart.map((x) => x.vkt)), Math.max(...bubbleChart.map((x) => x.vkt))], [1, 5]),
                      vkt: item.vkt,
                    }],
                }))}
                type="bubble"
                width="100%"
                height="100%"
              />
            </ChartContainer>
          </GridItem>
        </Grid>
      </Box>
      <Box my={8}>
        <ReportSubheading>The percentage of travel on roads smoother than the threshold for each traffic grouping</ReportSubheading>
        <Text mb={8}>
          These graphs show the annual change of smooth travel on the roads in each ONF Street Category
          and the linear trend (green is improving, red is declining).
        </Text>
        <SimpleGrid columns={[2, 2, 4, 4, 6, 6]} spacing={2}>
          {transformedDataByCategory.map((cat) => (
            <GridItem
              as={ChartContainer}
              key={`cat_${cat.name}`}
              p={2}
              minH="280"
            >
              <Chart
                options={
                  {
                    title: {
                      text: cat.name,
                      style: {
                        color: theme.colors.onfCategory[cat.name].base,
                      },
                    },
                    xaxis: {
                      categories: years,
                    },
                    yaxis: [
                      {
                        min: 0,
                        max: Math.max(...transformedDataByCategory.flatMap((x) => x.data.map((y) => y.value))),
                        forceNiceScale: true,
                        labels: {
                          formatter(val) {
                            return `${val.toFixed(0)}%`;
                          },
                        },
                      },
                    ],
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: 'straight',
                      width: [0, 4],
                    },
                    colors: [getTrendlineColor(cat.data.at(-1).trendY1, cat.data.at(-1).trendY2, true)],
                    fill: {
                      colors: [theme.colors.onfCategory[cat.name].base],
                    },
                    markers: {
                      size: 0,
                      hover: {
                        size: 0,
                      },
                    },
                    legend: {
                      show: false,
                    },
                    tooltip: {
                      enabled: true,
                      enabledOnSeries: [0],
                      marker: {
                        show: false,
                      },
                    },
                  }
                }
                series={
                  [
                    {
                      name: cat.name,
                      type: 'column',
                      data: cat.data.map((x) => x.value),
                    },
                    {
                      name: 'Trend',
                      type: 'line',
                      data: getRangeOfPoints(cat.data.at(-1).trendY1, cat.data.at(-1).trendY2, years.length),
                    },
                  ]
                }
                type="line"
                width="100%"
                height="100%"
              />
            </GridItem>
          ))}
        </SimpleGrid>
      </Box>
      {networkSelector}
      <Box my={8}>
        <ReportSubheading>
          The percentage of travel on roads smoother than the threshold for each ONF Street Category
        </ReportSubheading>
        <Text mb={8}>
          This chart shows the percentage of travel on smooth roads for your network compared to other networks you choose.
          If no other networks are chosen, the chart compares your RCA with their peer group, region and the National figures.
          {' '}
        </Text>
        <ChartContainer>
          <Chart
            options={
              {
                stroke: {
                  colors: ['transparent'],
                  width: 1,
                },
                colors: [rca.rcaBrandColour, ...COMPARISON_COLORS],
                yaxis: [
                  {
                    forceNiceScale: true,
                    tickAmount: 8,
                    labels: {
                      formatter(val) {
                        return `${val ? val.toFixed(0) : val}%`;
                      },
                    },
                    title: {
                      text: 'Percentage of travel on smooth roads',
                    },
                  },
                ],
                xaxis: {
                  categories: filteredCategories.map((cat) => cat.description),
                  labels: {
                    style: {
                      colors: filteredCategories.map((cat) => theme.colors.onfCategory[cat.description].base),
                      fontWeight: 'bold',
                    },
                  },
                  tickPlacement: 'between',
                },
                tooltip: {
                  intersect: false,
                  shared: true,
                  followCursor: true,
                  marker: {
                    show: true,
                  },
                },
                dataLabels: {
                  enabled: false,
                  formatter(val) {
                    return val || 0;
                  },
                },
                legend: {
                  onItemClick: {
                    toggleDataSeries: false,
                  },
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: true,
                    },
                  },
                },
              }
            }
            series={transformedDataByRca}
            type="bar"
            width="100%"
            height="500"
          />
        </ChartContainer>
      </Box>
      <CollapsedContent collapsedLabel="View Data Table" openLabel="Hide Data Table">
        <Box border="1px solid" borderColor="gray.100" my={4}>
          <TableContainer>
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th position="sticky" left="0" background="gray.50" py={4} whiteSpace="nowrap" width="1px">
                    Network
                  </Th>
                  <Th background="gray.50">Year</Th>
                  {filteredCategories.map((cat, i) => (
                    <Th key={`cat_${i}`} color={getContrastColor(theme.colors.onfCategory[cat.description].base)} background={theme.colors.onfCategory[cat.description].base}>
                      {cat.description}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {summaryData.map((network, idx1) => network.data.map(({ year, data }, idx2) => (
                  <Tr key={`row_${idx1}_${idx2}`}>
                    {idx2 === 0
                      && (
                      <Td
                        position="sticky"
                        left="0"
                        background="gray.50"
                        rowSpan={network.data.length}
                        borderBottom="1px solid"
                        borderColor="gray.200"
                      >
                        {network.name}
                      </Td>
                      )}
                    <Td
                      borderBottom="1px solid"
                      borderColor={idx2 === network.data.length - 1 ? 'gray.200' : 'gray.100'}
                    >
                      {year}
                    </Td>
                    {
                      filteredCategories.map((cat) => (
                        <Td
                          key={`cat_${idx1}_${Math.random()}`}
                          borderBottom="1px solid"
                          borderColor={idx2 === network.data.length - 1 ? 'gray.200' : 'gray.100'}
                        >
                          {data.find((x) => x.category === cat.description)?.value ? `${data.find((x) => x.category === cat.description)?.value}%` : '-'}
                        </Td>
                      ))
                    }
                  </Tr>
                )))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </CollapsedContent>
      <CollapsedContent collapsedLabel="View Thresholds" openLabel="Hide Thresholds" mt={4}>
        <Box border="1px solid" borderColor="gray.100" my={4}>
          {reportType === 'ONFVPD'
          && (
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    Traffic Volume
                  </Th>
                  <Th>
                    Urban NAASRA
                  </Th>
                  <Th>
                    Rural NAASRA
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>&lt; 500</Td>
                  <Td>&lt;= 180</Td>
                  <Td>&lt;= 150</Td>
                </Tr>
                <Tr>
                  <Td>500 - 999</Td>
                  <Td>&lt;= 150</Td>
                  <Td>&lt;= 150</Td>
                </Tr>
                <Tr>
                  <Td>1000 - 3999</Td>
                  <Td>&lt;= 150</Td>
                  <Td>&lt;= 130</Td>
                </Tr>
                <Tr>
                  <Td>4000 - 9999</Td>
                  <Td>&lt;= 120</Td>
                  <Td>
                    &lt;= 130
                  </Td>
                </Tr>
                <Tr>
                  <Td>&gt;= 10000</Td>
                  <Td>&lt;= 110</Td>
                  <Td>&lt;= 130</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          )}
          {reportType === 'ONFMP'
          && (
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    Family Type
                  </Th>
                  <Th>
                    Movement Classes
                  </Th>
                  <Th>
                    Smooth Threshold
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Rural</Td>
                  <Td>M1, M2, M3</Td>
                  <Td>130</Td>
                </Tr>
                <Tr>
                  <Td>Rural</Td>
                  <Td>M4, M5</Td>
                  <Td>150</Td>
                </Tr>
                <Tr>
                  <Td>Urban</Td>
                  <Td>M1</Td>
                  <Td>110</Td>
                </Tr>
                <Tr>
                  <Td>Urban</Td>
                  <Td>M2</Td>
                  <Td>120</Td>
                </Tr>
                <Tr>
                  <Td>Urban</Td>
                  <Td>M3, M4</Td>
                  <Td>150</Td>
                </Tr>
                <Tr>
                  <Td>Urban</Td>
                  <Td>M5</Td>
                  <Td>180</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          )}
        </Box>
      </CollapsedContent>
    </>
  );
}
