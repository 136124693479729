import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  FormErrorMessage,
  Text,
  ButtonGroup,
  HStack,
  VStack,
  InputLeftAddon,
  Flex,
  Icon,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { QUARTERS } from '@src/modules/performance/shared/constants';
import { FiInfo } from 'react-icons/fi';
import { useCreateTtmResult, useUpdateTtmResult } from '../../../../context/temporary-traffic-management-api-hooks';

const schema = yup.object().shape({
  nltpCost: yup
    .string()
    .required('NLTP Cost is required')
    .matches(/^[0-9,.\s]*$/, 'Invalid cost format')
    .test('is-money', 'Invalid cost format', (value) => !Number.isNaN(parseFloat(value.replace(/[^0-9.-]+/g, ''))))
    // eslint-disable-next-line func-names
    .test('is-less-than-max-digits', 'NLTP Cost should not have more than 15 digits before and 4 digits after the decimal point', (value) => {
      const numericValue = value.replace(/[^0-9.-]+/g, '');
      const [integerPart, decimalPart] = numericValue.split('.');
      const integerValid = integerPart.replace(/[^0-9]+/g, '').length <= 15;
      const decimalValid = !decimalPart || decimalPart.length <= 4;
      return integerValid && decimalValid;
    }),
  ttmCost: yup
    .string()
    .required('TTM Cost is required')
    .matches(/^[0-9,.\s]*$/, 'Invalid cost format')
    .test('is-money', 'Invalid cost format', (value) => !Number.isNaN(parseFloat(value.replace(/[^0-9.-]+/g, ''))))
    // eslint-disable-next-line func-names
    .test('is-less-than-nltp-cost', 'TTM Cost should not be more than NLTP cost', function (value) {
      const { nltpCost } = this.parent;
      const numericTtmCost = value.replace(/[^0-9.-]+/g, '');
      const numericNltpCost = nltpCost.replace(/[^0-9.-]+/g, '');
      const ttmCostValue = parseFloat(numericTtmCost);
      const nltpCostValue = parseFloat(numericNltpCost);
      return ttmCostValue <= nltpCostValue;
    })
    .test('is-less-than-max-digits', 'TTM Cost should not have more than 15 digits before and 4 digits after the decimal point', (value) => {
      const numericValue = value.replace(/[^0-9.-]+/g, '');
      const [integerPart, decimalPart] = numericValue.split('.');
      const integerValid = integerPart.replace(/[^0-9]+/g, '').length <= 15;
      const decimalValid = !decimalPart || decimalPart.length <= 4;
      return integerValid && decimalValid;
    }),
  ttmCostConfidence: yup
    .string()
    .required('Confidence in cost is required'),
  completedSiteInspections: yup
    .number()
    .typeError('Must be a number')
    .required('Total Site Inspections is required')
    .min(0, 'Total site inspections should be greater than or equal to 0'),
  unattendedSiteInspections: yup
    .number()
    .typeError('Must be a number')
    .required('Total Redundant Sites is required')
    .min(0, 'Total redundant sites should be greater than or equal to 0')
    // eslint-disable-next-line func-names
    .test('is-less-than-completed', 'Redundant Sites cannot be more than total site inspections', function (value) {
      const { completedSiteInspections } = this.parent;
      return value <= completedSiteInspections;
    }),
});

function formatAsMoney(value) {
  if (value === null || value === '') return '';
  const parts = value.toString().replace(/[^0-9.]+/g, '').split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

function TtmResultForm({
  isOpen, onClose, selectedEntry, isTestMode,
}) {
  const {
    id, quadDatabaseId, quadLockYear, quarter, ttmCostConfidence, unattendedSiteInspections, completedSiteInspections, ttmCost, nltpCost,
  } = selectedEntry;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ttmCost: formatAsMoney(ttmCost),
      nltpCost: formatAsMoney(nltpCost),
      ttmCostConfidence,
      unattendedSiteInspections,
      completedSiteInspections,
    },
    resolver: yupResolver(schema),
  });
  const { mutateAsync: doCreateTtmResult, isLoading: isCreating } = useCreateTtmResult();
  const { mutateAsync: doUpdateTtmResult, isLoading: isUpdating } = useUpdateTtmResult();

  const modalTitle = id ? 'Update TTM Result' : 'Add TTM Result';
  const submitButtonLabel = id ? 'Update Result' : 'Save Result';

  // Reset form when selected quarter changes
  useEffect(() => {
    reset({
      ttmCost: formatAsMoney(ttmCost),
      nltpCost: formatAsMoney(nltpCost),
      ttmCostConfidence: ttmCostConfidence ? parseInt(ttmCostConfidence, 10) : 3,
      unattendedSiteInspections,
      completedSiteInspections,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntry]);

  // Format cost as money when input changes
  const handleTtmCostChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatAsMoney(value);
    setValue('ttmCost', formattedValue, { shouldValidate: true });
  };

  const handleNltpCostChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatAsMoney(value);
    setValue('nltpCost', formattedValue, { shouldValidate: true });
  };

  const handleClose = () => {
    reset({
      ttmCost: formatAsMoney(ttmCost),
      nltpCost: formatAsMoney(nltpCost),
      ttmCostConfidence: 3,
      unattendedSiteInspections: null,
      completedSiteInspections: null,
    });
    onClose();
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      ttmCost: parseFloat(data.ttmCost.replace(/[^0-9.-]+/g, '')),
      nltpCost: parseFloat(data.nltpCost.replace(/[^0-9.-]+/g, '')),
      quarter,
      quadLockYear,
      quadDatabaseId,
    };

    // If testMode is on tell the api to ignore date checks
    if (isTestMode) {
      formData.isTestMode = true;
    }

    // If id exists we are updating an existing record
    // Otherwise we are creating a new record
    if (id) {
      formData.id = id;
      await doUpdateTtmResult(formData);
    } else {
      await doCreateTtmResult(formData);
    }

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          { modalTitle }
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <HStack mb={4} spacing={8}>
              <FormControl width="fit-content">
                <FormLabel mb={0}>Financial Year</FormLabel>
                <Text fontSize="xl">{quadLockYear}</Text>
              </FormControl>
              <FormControl width="fit-content">
                <FormLabel mb={0}>Entry Quarter</FormLabel>
                <Text fontSize="xl">
                  {QUARTERS.find((x) => x.id === quarter)?.label}
                </Text>
              </FormControl>
            </HStack>
            <Flex
              border="1px solid"
              borderColor="blue.100"
              p={2}
              borderRadius="base"
              mb={4}
            >
              <Icon as={FiInfo} boxSize={4} color="blue.600" />
              <Text color="blue.600" fontSize="xs" ml={2}>
                Note: Costs are required to be year to date, up to end of the quarter.  Site counts are just for the quarter.
              </Text>
            </Flex>
            <FormControl mb={4} isInvalid={!!errors.nltpCost}>
              <FormLabel>NLTP YTD Cost ($000&apos;s)</FormLabel>
              <InputGroup width="60%">
                <InputLeftAddon>$</InputLeftAddon>
                <Input
                  type="text"
                  {...register('nltpCost')}
                  onChange={handleNltpCostChange}
                />
              </InputGroup>
              <FormErrorMessage>{errors.nltpCost && errors.nltpCost.message}</FormErrorMessage>
            </FormControl>
            <FormControl mb={4} isInvalid={!!errors.ttmCost}>
              <FormLabel>TTM YTD Cost ($000&apos;s)</FormLabel>
              <InputGroup width="60%">
                <InputLeftAddon>$</InputLeftAddon>
                <Input
                  type="text"
                  {...register('ttmCost')}
                  onChange={handleTtmCostChange}
                />
              </InputGroup>
              <FormErrorMessage>{errors.ttmCost && errors.ttmCost.message}</FormErrorMessage>
            </FormControl>
            <FormControl mb={4} isInvalid={!!errors.ttmCostConfidence}>
              <FormLabel>Confidence in TTM Cost</FormLabel>
              <Controller
                name="ttmCostConfidence"
                control={control}
                defaultValue={3}
                render={({ field }) => (
                  <VStack align="stretch" width="fit-content">
                    <ButtonGroup>
                      {Array.from({ length: 5 }, (_, i) => i + 1).map((value) => (
                        <Button
                          key={value}
                          variant={field.value === value ? 'solid' : 'outline'}
                          onClick={() => field.onChange(value)}
                          size="sm"
                        >
                          {value}
                        </Button>
                      ))}
                    </ButtonGroup>
                    <HStack justify="space-between" width="100%">
                      <Text fontSize="xs">Low</Text>
                      <Text fontSize="xs">High</Text>
                    </HStack>
                  </VStack>
                )}
              />
              <FormErrorMessage>{errors.ttmCostConfidence && errors.ttmCostConfidence.message}</FormErrorMessage>
            </FormControl>
            <FormControl mb={4} isInvalid={!!errors.completedSiteInspections}>
              <FormLabel>Total Site Inspections for the quarter</FormLabel>
              <Input width="25%" type="number" {...register('completedSiteInspections')} />
              <FormErrorMessage>{errors.completedSiteInspections && errors.completedSiteInspections.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.unattendedSiteInspections}>
              <FormLabel>Total Redundant Sites for the quarter</FormLabel>
              <Input width="25%" type="number" {...register('unattendedSiteInspections')} />
              <FormErrorMessage>{errors.unattendedSiteInspections && errors.unattendedSiteInspections.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button colorScheme="orange" type="submit" isLoading={isCreating || isUpdating}>
              {submitButtonLabel}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default TtmResultForm;
