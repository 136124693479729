import { useStateHighwaysPotholeRepairsKPI } from '@src/modules/performance/context/reports-api-hooks';
import { useIsUserInRole } from '@transport-insights/uikit';
import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { CircleIndicator } from '../Indicators';
import { roundKpiValue } from '../../shared/helpers';

export default function StateHighwayPotholeRepairsWidget() {
  const { data: stateHighwaysPotholeRepairsData, isLoading, isError } = useStateHighwaysPotholeRepairsKPI();
  const hasDeveloperRole = useIsUserInRole('Developer');

  const title = 'State Highways\nPothole Repairs';
  const description = '% completed within 24 hours';

  // If there is an error and no data we want to hide the widget
  if (isError && (stateHighwaysPotholeRepairsData?.percentAchieved === undefined || !stateHighwaysPotholeRepairsData?.percentAchieved)) return null;

  return (
    <Widget
      link={hasDeveloperRole ? '/performance/dashboard/state-highways-pothole-repairs' : null}
      title={title}
      description={description}
      lockYear={stateHighwaysPotholeRepairsData?.lockYear}
      quarter={stateHighwaysPotholeRepairsData?.quarter}
      quarterFormat="ytd"
      isLoading={isLoading && !stateHighwaysPotholeRepairsData}
      moreInfoContent={(
        <Text fontSize="sm">
          The year to date percentage of potholes on all State Highways that were repaired within 24 hours, to the end of the quarter.
        </Text>
      )}
    >
      <CircleIndicator
        size={100}
        colour={stateHighwaysPotholeRepairsData?.percentAchieved !== undefined ? 'gray.700' : null}
        value={stateHighwaysPotholeRepairsData?.percentAchieved !== null && stateHighwaysPotholeRepairsData?.percentAchieved !== undefined ? `${roundKpiValue(stateHighwaysPotholeRepairsData?.percentAchieved, 5, 1)}%` : null}
        isLoading={isLoading}
        padding={0}
      />
    </Widget>
  );
}
