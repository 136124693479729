import { Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';

// Function to calculate the progress of the entry period
export const calculateProgress = (startDate, endDate) => {
  // Parse dates using Luxon
  const now = DateTime.now().startOf('day');
  const start = DateTime.fromISO(startDate).startOf('day');
  const end = DateTime.fromISO(endDate).startOf('day');

  // Calculate total days and days until start and end
  const totalDays = end.diff(start, 'days').days;
  const daysUntilStart = Math.max(0, start.diff(now, 'days').days);
  const daysUntilEnd = Math.max(0, end.diff(now, 'days').days);

  let progress = 0;
  let phase = 'before';

  if (now < start) {
    // Before the entry period starts
    progress = ((totalDays - daysUntilStart) / totalDays) * 100;
    phase = 'before';
  } else if (now >= start && now <= end) {
    // During the entry period
    progress = ((now.diff(start, 'days').days) / totalDays) * 100;
    phase = 'during';
  } else {
    // After the entry period ends
    progress = 100;
    phase = 'after';
  }

  return {
    progress: Math.min(100, Math.max(0, progress)),
    daysUntilStart,
    daysUntilEnd,
    phase,
  };
};

// Function to format time as human readable
export const formatTimeAsHumanReadable = (value) => {
  if (!value) return '';
  const [hours, minutes] = value.split(':');
  // Format value as #hr #min
  // Remove leading 0s
  // Deal with singular/plural
  // Deal with 0 hours and 0 minutes
  const formattedHours = hours?.replace(/^0+/, '') || null;
  const formattedMinutes = minutes?.replace(/^0+/, '') || null;
  const hoursText = formattedHours === '1' ? 'hr' : 'hrs';
  const minutesText = formattedMinutes === '1' ? 'min' : 'mins';
  return (formattedHours ? `${formattedHours}${hoursText}` : '') + (formattedMinutes ? ` ${formattedMinutes}${minutesText}` : '');
};

// Calculate percentage for the repaired fields
export const calculatePercentage = (total, value) => {
  const numIdentified = Number(total) || 0;
  const percentage = numIdentified ? ((Number(value) / numIdentified) * 100).toFixed(0) : 0;
  return (
    <Text fontSize="sm" color={percentage > 100 ? 'red.600' : 'gray.600'} mt={0} mb={0}>
      {percentage}
      %
    </Text>
  );
};
