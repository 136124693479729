import {
  Flex,
} from '@chakra-ui/react';
import { QUARTERS } from '@src/modules/performance/shared/constants';
import { useEffect, useState } from 'react';

const badgeStyles = {
  border: '1px solid',
  borderColor: 'gray.200',
  color: 'gray.700',
  fontSize: '12px',
  textAlign: 'center',
  fontWeight: 'bold',
  py: '1px',
  px: 2,
  borderRadius: 'full',
  flexDirection: 'row',
  align: 'center',
  cursor: 'default',
  minWidth: 'auto',
  flexShrink: 0,
};

const quarterBadgeStyles = {
  ...badgeStyles,
  borderColor: 'gray.400',
  color: 'white',
  bg: 'gray.400',
};

function LockYearBadge({
  lockYear, quarter, quarterFormat = 'month', align = 'center', justify = 'center',
}) {
  const [quarterFormatLabel, setQuarterFormatLabel] = useState(null);

  useEffect(() => {
    if (quarterFormat === 'ytd') {
      setQuarterFormatLabel('(YTD)');
    } else if (quarterFormat === 'ltp') {
      setQuarterFormatLabel('(LTP)');
    } else {
      const quarterObj = QUARTERS.find((q) => q.id === parseInt(quarter, 10));
      if (quarterObj) {
        setQuarterFormatLabel(
          `(${quarterObj.startMonth}-${quarterObj.endMonth})`,
        );
      } else {
        setQuarterFormatLabel('');
      }
    }
  }, [quarter, quarterFormat]);

  if (!lockYear) {
    return <Flex {...badgeStyles} visibility="hidden">None</Flex>;
  }

  return (
    <Flex align={align} justify={justify}>
      <Flex {...badgeStyles} className="lockyear-badge">
        {lockYear}
      </Flex>
      {quarter && (
        <Flex {...quarterBadgeStyles} ml={1} className="quarter-badge">
          {QUARTERS.find((q) => q.id === parseInt(quarter, 10))?.label}
          {' '}
          {quarterFormatLabel}
        </Flex>
      )}
    </Flex>
  );
}

export default LockYearBadge;
