import { Checkbox, HStack, Text } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { tceWizardFilterOnlyMissingTrafficGroup } from '@tce/state/tce';
import { useEffect } from 'react';
import { useJob } from '@src/modules/tce/src/context/jobs';

function Step3Filter({ name }) {
  const { job } = useJob();
  const [onlyMissingTrafficGroup, setOnlyMissingTrafficGroup] = useRecoilState(tceWizardFilterOnlyMissingTrafficGroup(name));

  const onChangeOnlyMissingTrafficGroup = () => setOnlyMissingTrafficGroup(!onlyMissingTrafficGroup);

  useEffect(
    () => {
      if (job && job.status === 'RESULTS') {
        setOnlyMissingTrafficGroup(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [job],
  );

  return (
    <HStack w="full">
      <Checkbox
        colorScheme="blue"
        isChecked={onlyMissingTrafficGroup}
        onChange={(e) => onChangeOnlyMissingTrafficGroup(e)}
      >
        <Text as="label" fontSize="sm">Only show Carriageways with no Traffic Group</Text>
      </Checkbox>
    </HStack>
  );
}

export {
  Step3Filter,
};
