import MainLayout from '@src/components/layouts/main/MainLayout';
import Report from './Report';
import MainNav from '../shared/components/MainNav';

export default function Admin() {
  return (
    <MainLayout>
      <MainNav />
      <Report />
    </MainLayout>
  );
}
