import { useAuthenticatedApi } from '@transport-insights/uikit';
import { useConfig } from '@src/context/config';

export default () => {
  const { PERFORMANCE_API_URL } = useConfig();
  const api = useAuthenticatedApi(PERFORMANCE_API_URL);

  return {
    async getQuarterlyReport(lockYear, quarter) {
      const url = `/quarterly-reporting/report/${lockYear}/${quarter}`;
      const res = await api.get(url);
      return res?.data || undefined;
    },
    async generateQuarterlyReport(lockYear, quarter) {
      const convertedLockYear = lockYear?.replace('/', '-');
      const url = `/quarterly-reporting/report/${convertedLockYear}/${quarter}/download`;
      const res = await api.get(url, { responseType: 'blob' });
      return res?.data || undefined;
    },
    async downloadQuarterlyReport(lockYear, quarter, reportId) {
      const convertedLockYear = lockYear?.replace('/', '-');
      const url = `/quarterly-reporting/report/${convertedLockYear}/${quarter}/download/${reportId}`;
      const res = await api.get(url, { responseType: 'blob' });
      return res?.data || undefined;
    },
    async getQuarterlyReportOptions() {
      const url = '/quarterly-reporting/report/options';
      const res = await api.get(url);
      return res?.data || undefined;
    },
    async activateQuarterlyResults({ quarterNumber, lockYear, isTestMode }) {
      const res = await api.post(`/quarterly-reporting/report/publish${isTestMode ? '?force=true' : ''}`, { quarterNumber, lockYear }, {
        responseType: 'json',
      });
      return res?.data || null;
    },
    async deactivateQuarterlyResults({ quarterNumber, lockYear }) {
      const res = await api.post('/quarterly-reporting/report/unpublish', { quarterNumber, lockYear }, {
        responseType: 'json',
      });
      return res?.data || null;
    },
  };
};
