import {
  Container,
  Heading,
  Flex,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main';
import ImportSelector from './components/ImportSelector';
import MoreDrawer from '../../shared/MoreDrawer';

export default function TrafficCountImport() {
  return (
    <MainLayout bgColor="gray.50">
      <Container maxW="full" flexDirection="column" display="flex" flexGrow={1}>
        <Flex align="center" justify="space-between">
          <Heading as="h1">Traffic Count Estimates</Heading>
          <MoreDrawer />
        </Flex>
        <ImportSelector />
      </Container>
    </MainLayout>
  );
}
