import { selectedDatabaseIdState } from '@transport-insights/uikit';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { useToast } from '@chakra-ui/react';
import usePotholesApi from '../api/usePotholesApi';

export function usePotholesList() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const api = usePotholesApi();

  return useQuery(
    ['potholes', rcaId],
    async () => api.getPotholesList(rcaId),
  );
}

export const useCreatePotholesResult = () => {
  const api = usePotholesApi();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    (potholeResult) => api.createPotholesResult(potholeResult).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('potholes');
        queryClient.invalidateQueries('potholes-kpi');
        toast({
          title: 'Success',
          description: 'Result was successfully created.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.response.data.Message ? error.response.data.Message : 'Result could not be created. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );
};

export const useUpdatePotholesResult = () => {
  const api = usePotholesApi();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    (potholeResult) => api.updatePotholesResult(potholeResult).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('potholes');
        queryClient.invalidateQueries('potholes-kpi');
        toast({
          title: 'Success',
          description: 'Result was successfully updated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.response.data.Message ? error.response.data.Message : 'Result could not be updated. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );
};

export function useOnrcCategories() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const api = usePotholesApi();

  return useQuery(
    ['onrc-categories', rcaId],
    async () => api.getOnrcCategories(rcaId),
  );
}
