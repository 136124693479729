import {
  HStack,
  IconButton, Text,
} from '@chakra-ui/react';
import { pageSizeState, tceWizardInvalidDataEntryState } from '@tce/state/tce';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { useRecoilState, useRecoilValue } from 'recoil';

export default function Pagination(
  {
    currentPage = 0,
    firstDisplayedRow = 0, // has 0 offset
    totalRows,
    onNext,
    onPrevious,
  },
) {
  const [pageSize] = useRecoilState(pageSizeState);
  const tceWizardInvalidDataEntry = useRecoilValue(tceWizardInvalidDataEntryState);
  const firstRowOnPage = firstDisplayedRow + (totalRows > 0 ? 1 : 0);
  let lastRowOnPage = firstDisplayedRow + pageSize;
  let lastPage = false;
  if (lastRowOnPage >= totalRows) {
    lastRowOnPage = totalRows;
    lastPage = true;
  }

  return (
    <HStack align="center">
      <Text>
        {firstRowOnPage}
        -
        {lastRowOnPage}
        {' '}
        of
        {' '}
        {totalRows}
      </Text>
      <IconButton
        bgGradient="linear(to-b, gray.50, gray.100)"
        colorScheme="gray"
        disabled={currentPage === 0 || (tceWizardInvalidDataEntry !== null)}
        icon={<FiArrowLeft />}
        onClick={onPrevious}
        borderColor="gray.100"
        border="1px solid"
        size="sm"
      />
      <IconButton
        bgGradient="linear(to-b, gray.50, gray.100)"
        colorScheme="gray"
        disabled={lastPage || tceWizardInvalidDataEntry !== null}
        icon={<FiArrowRight />}
        onClick={onNext}
        borderColor="gray.100"
        border="1px solid"
        size="sm"
      />
    </HStack>
  );
}
