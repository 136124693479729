import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
  useTheme,
  Text,
  GridItem,
  Grid,
  SimpleGrid,
} from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import { groupBy } from 'lodash';

import {
  getRangeOfPoints, convertBubbleRange, roundToDecimal, getTrendDirection,
} from '@src/utils/math';
import { getContrastColor, getTrendlineColor, RAG_COLORS } from '@src/utils/color';
import { useFilteredCategories } from '../../hooks/useFilteredCategories';
import { useTrendSvg } from '../../hooks/useTrendSvg';
import ReportSubheading from '../shared/ReportSubheading';
import CollapsedContent from '../shared/CollapsedContent';
import ChartContainer from '../shared/ChartContainer';

export default function Hsp3({
  filters, selectedToggleOption, reportData, networkSelector,
}) {
  const theme = useTheme();
  const { upRedSvg, downGreenSvg, flatSvg } = useTrendSvg();
  const filteredCategories = useFilteredCategories(filters);
  const rca = reportData.rcas[0];
  const filteredReportData = reportData.data.filter((x) => Object.entries(selectedToggleOption).every(([k, v]) => v.includes(x[k])));
  const selectedRcaData = filteredReportData.filter((x) => x.id === rca.id);
  const years = [...new Set(filteredReportData.map((x) => x.latestYear))].sort();
  const currentYearOverview = selectedRcaData.filter((x) => x.latestYear === years.at(-1))
    .filter((x) => x.count > 0)
    .map((item) => ({
      category: item.category,
      value: item.count,
      trendM: item.trendM,
      length: roundToDecimal(item.onf_length_km, 1),
      trendY1: item.trendB,
      trendY2: item.trendB + (item.trendM * 5),
      trendDirection: getTrendDirection(item.trendB, item.trendM, 5),
    }));
  const transformedDataByYear = Object.entries(groupBy(selectedRcaData, 'latestYear'))
    .map(([k, v]) => ({
      year: k,
      data: v.map((item) => ({
        category: item.category,
        value: item.count,
      })),
    }));
  const transformedDataByCategory = Object.entries(groupBy(selectedRcaData, 'category'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        year: item.latestYear,
        value: item.count,
        trendY1: item.trendB,
        trendY2: item.trendB + (item.trendM * 5),
      })),
    }));
  const transformedDataByRca = Object.entries(groupBy(filteredReportData, 'rcaName'))
    .map(([k, v]) => ({
      name: k,
      data: Object.entries(groupBy(v, 'category'))
        .map(([cat, catData]) => {
          // PMRT-5753
          // We need to check if there are any counts across the years for the category
          // If there are counts but the latest year trend is 0, we need to set the trend to 0.01
          // So we can display this correctly in the heatmap
          const totalCount = catData.reduce((acc, item) => acc + item.count, 0);
          const latestYear = catData.at(-1);
          return { x: cat, y: totalCount > 0 && latestYear.trend_pct === 0 ? 0.0001 : roundToDecimal(latestYear.trend_pct, 2), trendPct: roundToDecimal(latestYear.trend_pct, 2) };
        }),
    }));

  // Need to split the HNO name into 2 lines
  const splitHnoName = (name) => {
    if (!name.includes(';')) return name;
    const nameArray = name.split(';').join(';');
    const half = Math.ceil(nameArray.length / 2);
    return [nameArray.slice(0, half), nameArray.slice(-half)];
  };

  const { red, green, gray } = RAG_COLORS;

  return (
    <>
      <Grid templateColumns={{ md: '100%', lg: '1fr 1fr', xl: '1fr 2fr' }} gap={4}>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>
            Counts for
            {' '}
            {years.at(-1)}
          </ReportSubheading>
          <Text mb={8}>This chart shows the percentage of deaths and serious injuries in each category for the year, that are in crashes involving the selected situation.</Text>
          <ChartContainer>
            <Chart
              options={
                {
                  dataLabels: {
                    enabled: true,
                  },
                  colors: currentYearOverview.map((x) => theme.colors.onfCategory[x.category].base),
                  legend: {
                    show: true,
                    position: 'bottom',
                  },
                  tooltip: {
                    enabled: true,
                    enabledOnSeries: [0],
                    marker: {
                      show: false,
                    },
                  },
                  labels: currentYearOverview.map((x) => x.category),
                }
              }
              series={currentYearOverview.map((x) => x.value)}
              type="pie"
              width="100%"
            />
          </ChartContainer>
        </GridItem>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>
            Counts for
            {' '}
            {years.at(-1)}
            {' '}
            and 5 year trends
          </ReportSubheading>
          <Text mb={8}>
            The bars show the count of deaths and serious injuries in each category for the year, that are in crashes involving the selected situation.
            The arrow above each bar shows whether the counts are trending up (getting worse = red) or down (improving = green).
          </Text>
          <ChartContainer>
            <Chart
              options={
                {
                  xaxis: {
                    type: 'category',
                    categories: [...new Set(currentYearOverview.map((x) => x.category))],
                    labels: {
                      style: {
                        colors: currentYearOverview.map((x) => theme.colors.onfCategory[x.category].base),
                        fontWeight: 'bold',
                      },
                    },
                    tickPlacement: 'between',
                  },
                  yaxis: [
                    {
                      forceNiceScale: true,
                      title: {
                        text: 'Reported DSI counts',
                      },
                    },
                  ],
                  dataLabels: {
                    enabled: false,
                  },
                  colors: currentYearOverview.map((x) => theme.colors.onfCategory[x.category].base),
                  legend: {
                    show: false,
                  },
                  tooltip: {
                    enabled: true,
                    enabledOnSeries: [0],
                    marker: {
                      show: false,
                    },
                    custom({ seriesIndex, dataPointIndex, w }) {
                      const {
                        x, y, trend, length,
                      } = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                      return `<div style="padding: 10px">
                      <p style="color: ${theme.colors.onfCategory[x].base}; font-weight: bold; margin-bottom: 10px;">${x}</p> 
                      <ul>
                      <li><b>DSI Count</b>: ${y}</li>
                      <li><b>Trend</b>: ${trend}</li>
                      <li><b>Length</b>: ${length} km</li>
                      </ul></div>`;
                    },
                  },
                  plotOptions: {
                    bar: {
                      distributed: true,
                      horizontal: false,
                    },
                  },
                  annotations: {
                    points: currentYearOverview.map((item) => (
                      {
                        x: item.category,
                        y: item.value,
                        marker: {
                          size: 0,
                        },
                        customSVG: {
                          // eslint-disable-next-line no-nested-ternary
                          SVG: item.trendDirection === 'flat' ? flatSvg : item.trendDirection === 'up' ? upRedSvg : downGreenSvg,
                          cssClass: undefined,
                          offsetX: -30,
                          offsetY: -50,
                        },
                      }
                    )),
                  },
                }
              }
              series={
                [
                  {
                    name: years.at(-1),
                    data: currentYearOverview.map((x) => ({
                      y: x.value, x: x.category, trend: x.trendM, length: x.length,
                    })),
                  },
                ]
              }
              type="bar"
              width="100%"
              height="400"
            />
          </ChartContainer>
        </GridItem>
      </Grid>
      <Box my={8}>
        <ReportSubheading>
          Counts for
          {' '}
          {years.at(-1)}
          {' '}
          and 5 year trends
        </ReportSubheading>
        <Grid templateColumns={{ md: '100%', lg: '1fr 3fr', xl: '1fr 4fr' }} gap={4}>
          <GridItem>
            <Text mb={8}>
              This chart shows how much the DSI counts are improving or getting worse, for the selected type of situation.
              The higher the category bubble is above the 0 trend line, the worse the category count is increasing. Categories below the 0 trend line have reducing counts.
              The size of the bubble indicates how many counts for the category and situation in the year.
              The trends are calculated from the counts for the last five years for each category and selected situation.
            </Text>
          </GridItem>
          <GridItem>
            <ChartContainer minHeight="550">
              <Chart
                options={
              {
                colors: currentYearOverview.map((x) => theme.colors.onfCategory[x.category].base),
                grid: {
                  padding: {
                    left: 20,
                    bottom: 50,
                  },
                },
                legend: {
                  offsetY: -20,
                },
                xaxis: {
                  type: 'numeric',
                  title: {
                    text: 'Street Category Network Length (km)',
                    offsetY: 10,
                  },
                  tickAmount: 10,
                  min: -5,
                  max: Math.round(Math.max(...currentYearOverview.map((x) => x.length)) * 1.1),
                  labels: {
                    formatter(val) {
                      if (val < 0) return '0 km';
                      return `${val.toFixed(0)} km`;
                    },
                  },
                },
                yaxis: [
                  {
                    forceNiceScale: true,
                    min: (min) => Math.round((min + -0.5) * 1.2),
                    max: (max) => Math.round((max + 0.5) * 1.2),
                    title: {
                      text: 'Trend (+ increasing, - decreasing)',
                    },
                    labels: {
                      formatter(val) {
                        return `${val.toFixed(1)}`;
                      },
                    },
                  },
                ],
                dataLabels: {
                  enabled: false,
                },
                fill: {
                  opacity: 0.8,
                },
                tooltip: {
                  marker: {
                    show: false,
                  },
                  z: {
                    title: 'DSI Count:',
                  },
                  y: {
                    title: {
                      formatter: () => 'Trend:',
                    },
                  },
                  x: {
                    show: true,
                    formatter: (val, { seriesIndex, w }) => `${w.globals.seriesNames[seriesIndex]}: ${val.toFixed(0)} km`,
                  },
                  custom({ seriesIndex, dataPointIndex, w }) {
                    const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                    const { name } = w.globals.initialSeries[seriesIndex];

                    return `<div style="padding: 10px">
                    <p style="color: ${theme.colors.onfCategory[name].base}; font-weight: bold; margin-bottom: 10px;">${name}</p> 
                    <ul>
                    <li><b>Length</b>: ${data.x} km</li>
                    <li><b>Trend</b>: ${data.y}</li>
                    <li><b>DSI Count</b>: ${data.dsiCount}</li> 
                    </ul></div>`;
                  },
                },
                annotations: {
                  position: 'back',
                  yaxis: [
                    {
                      x: 0,
                      strokeDashArray: 0,
                      borderColor: '#666',
                      borderWidth: 1,
                      opacity: 0.5,
                    },
                  ],
                },
                plotOptions: {
                  bubble: {
                    zScaling: true,
                  },
                },
              }
            }
                series={currentYearOverview.map((item) => ({
                  name: item.category,
                  data: [
                    {
                      x: item.length,
                      y: item.trendM,
                      z: convertBubbleRange(item.value, [Math.min(...currentYearOverview.map((x) => x.value)), Math.max(...currentYearOverview.map((x) => x.value))], [1, 8]),
                      dsiCount: item.value,
                    }],
                }))}
                type="bubble"
                width="100%"
                height="100%"
              />
            </ChartContainer>
          </GridItem>
        </Grid>
      </Box>
      <Box my={8}>
        <ReportSubheading>Counts by year</ReportSubheading>
        <Text mb={8}>
          This chart shows the cumulative DSI counts for the last five years in crashes involving the selected situation, for the chosen ONF categories.
          Select Urban or Rural or specific categories to see the combined counts for those categories.
        </Text>
        <ChartContainer>
          <Chart
            options={
              {
                chart: {
                  stacked: true,
                },
                dataLabels: {
                  enabled: false,
                },
                tooltip: {
                  enabled: true,
                  shared: true,
                  intersect: false,
                  inverseOrder: true,
                  x: {
                    show: true,
                    formatter: (val) => val,
                  },
                  y: {
                    formatter(value) {
                      if (value > 0) return value;
                      return null;
                    },
                  },
                },
                colors: filteredCategories.map((cat) => theme.colors.onfCategory[cat.description].base),
                xaxis: {
                  categories: years,
                  labels: {
                    formatter(val, ts, opts) {
                      const { i } = opts || 0;
                      const total = opts?.w?.globals.stackedSeriesTotals[i];
                      return [val, `(Total: ${total})`];
                    },
                  },
                },
                yaxis: [
                  {
                    forceNiceScale: true,
                    labels: {
                      formatter(val) {
                        return val.toFixed(0);
                      },
                    },
                    title: {
                      text: 'Reported DSI counts',
                    },
                  },
                ],
                legend: {
                  onItemClick: {
                    toggleDataSeries: false,
                  },
                },
              }
            }
            series={transformedDataByCategory.map((cat) => ({
              name: cat.name,
              data: cat.data.map((x) => x.value),
            }))}
            type="bar"
            width="100%"
            height="500"
          />
        </ChartContainer>
      </Box>
      <Box my={8}>
        <ReportSubheading>Counts by ONF street category</ReportSubheading>
        <Text mb={8}>This chart shows the last five years of DSI counts and the linear trend for crashes involving the selected situation, per category.</Text>
        <SimpleGrid columns={[2, 2, 4, 4, 6, 6]} spacing={2}>
          {transformedDataByCategory.map((cat) => (
            <GridItem
              as={ChartContainer}
              key={`cat_${cat.name}`}
              p={2}
              minH="280"
            >
              <Chart
                options={
                  {
                    title: {
                      text: cat.name,
                      style: {
                        color: theme.colors.onfCategory[cat.name].base,
                      },
                    },
                    xaxis: {
                      categories: years,
                    },
                    yaxis: [
                      {
                        min: 0,
                        max: 2 * Math.round(Math.max(...selectedRcaData.map((x) => x.count)) / 2),
                        forceNiceScale: true,
                        labels: {
                          formatter(val) {
                            return val.toFixed(0);
                          },
                        },
                      },
                    ],
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: 'straight',
                      width: [0, 4],
                    },
                    colors: [getTrendlineColor(cat.data.at(-1).trendY1, cat.data.at(-1).trendY2, false)],
                    fill: {
                      colors: [theme.colors.onfCategory[cat.name].base],
                    },
                    markers: {
                      size: 0,
                      hover: {
                        size: 0,
                      },
                    },
                    legend: {
                      show: false,
                    },
                    tooltip: {
                      enabled: true,
                      enabledOnSeries: [0],
                      marker: {
                        show: false,
                      },
                    },
                  }
                }
                series={
                  [
                    {
                      name: cat.name,
                      type: 'column',
                      data: cat.data.map((x) => x.value),
                    },
                    {
                      name: 'Trend',
                      type: 'line',
                      data: getRangeOfPoints(cat.data.at(-1).trendY1, cat.data.at(-1).trendY2, years.length),
                    },
                  ]
                }
                type="line"
                width="100%"
                height="100%"
              />
            </GridItem>
          ))}
        </SimpleGrid>
      </Box>
      <CollapsedContent collapsedLabel="View Data Table" openLabel="Hide Data Table">
        <Box border="1px solid" borderColor="gray.100" my={4}>
          <TableContainer>
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th position="sticky" left="0" background="gray.50" py={4}>DSI Counts</Th>
                  {filteredCategories.map((cat, i) => (
                    <Th key={`cat_${i}`} color={getContrastColor(theme.colors.onfCategory[cat.description].base)} background={theme.colors.onfCategory[cat.description].base}>
                      {cat.description}
                    </Th>
                  ))}
                  <Th position="sticky" right="0" background="gray.50">Total</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  transformedDataByYear.map(({ year, data }) => (
                    <Tr key={year}>
                      <Td position="sticky" left="0" background="gray.50">{year}</Td>
                      {filteredCategories.map((cat, i) => (
                        <Td key={`cat_${i}`}>
                          {data.find((x) => x.category === cat.description)?.value}
                        </Td>
                      ))}
                      <Td position="sticky" right="0" background="gray.50">
                        {
                          filteredCategories.reduce((total, cat) => {
                            const match = data.find((x) => x.category === cat.description);
                            if (match) {
                              return total + match.value;
                            }
                            return 0;
                          }, 0)
                        }
                      </Td>
                    </Tr>
                  ))
                }
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </CollapsedContent>
      {networkSelector}
      <Box my={8}>
        <ReportSubheading>Trend Heatmap</ReportSubheading>
        <Text mb={8}>
          This heatmap shows per category whether the annual DSI count for crashes in the situation over the last five years is getting worse (Red), reducing (Green), staying the same (Yellow), or there is no count in the category (Grey).
          The heatmap compares the RCA with their peer group, region and the National figures, or with other selected networks.
        </Text>
        <ChartContainer minH="500">
          <Chart
            options={
              {
                xaxis: {
                  labels: {
                    style: {
                      colors: filteredCategories.map((cat) => theme.colors.onfCategory[cat.description].base),
                      fontWeight: 'bold',
                    },
                  },
                  tickPlacement: 'between',
                },
                legend: {
                  show: true,
                  showForSingleSeries: true,
                  position: 'bottom',
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    radius: 2,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  onItemClick: {
                    toggleDataSeries: false,
                  },
                  onItemHover: {
                    highlightDataSeries: false,
                  },
                },
                dataLabels: {
                  enabled: true,
                  formatter(val) {
                    return `${val ? val.toFixed(1) : val}%`;
                  },
                },
                plotOptions: {
                  heatmap: {
                    enableShades: false,
                    colorScale: {
                      ranges: [{
                        from: -500.0,
                        to: -1,
                        color: green,
                        name: 'Decreasing trend of 1% or greater',
                      },
                      {
                        from: -0.99,
                        to: 0.99,
                        color: '#F7C622',
                        name: 'Between a 1% decrease and 1% increase',
                      },
                      {
                        from: 1.0,
                        to: 500,
                        color: red,
                        name: 'Increasing trend of 1% or greater',
                      },
                      {
                        from: 0,
                        to: 0,
                        color: gray,
                        name: 'No DSI',
                      },
                      ],
                    },
                  },
                },
                tooltip: {
                  enabled: false,
                  intersect: false,
                  shared: false,
                  followCursor: true,
                  marker: {
                    show: false,
                  },
                  y: {
                    formatter(val) {
                      return `${val ? val.toFixed(0) : val}%`;
                    },
                  },
                },
              }
            }
            series={transformedDataByRca}
            type="heatmap"
            width="100%"
            height="100%"
          />
        </ChartContainer>
      </Box>
      <Box my={8}>
        <ReportSubheading>Comparative Trend by ONF street category</ReportSubheading>
        <Text mb={8}>
          This graph shows how much the annual DSI counts over the last five years are getting worse (Red) or reducing (Green) for crashes in the situation.
          The graph compares the RCA with their peer group, region and the National figures, or with other selected networks. The percentage is calculated by dividing the five year trend by the average DSI count across the five years.
          E.g. if there was an average of 10 DSI per year and the trend is increasing by 1 DSI per year, the percentage will be 10%.
        </Text>
        <ChartContainer minH="700">
          <Chart
            options={
              {
                chart: {
                  stacked: false,
                },
                xaxis: {
                  categories: filteredCategories.map((cat) => cat.description),
                  labels: {
                    minHeight: 220,
                    style: {
                      colors: filteredCategories.map((cat) => theme.colors.onfCategory[cat.description].base),
                      fontWeight: 'bold',
                    },
                  },
                  tickPlacement: 'between',
                  axisBorder: {
                    offsetY: 100,
                  },
                },
                yaxis: [
                  {
                    reversed: true,
                    forceNiceScale: true,
                    tickAmount: 8,
                    labels: {
                      formatter(val) {
                        return `${val ? val.toFixed(1) : val}%`;
                      },
                    },
                    title: {
                      text: '% change in DSI per year',
                    },
                  },
                ],
                colors: [
                  ({ value }) => {
                    if (value > 0) {
                      return red;
                    }
                    return green;
                  },
                ],
                stroke: {
                  colors: ['transparent'],
                  width: 2,
                },
                dataLabels: {
                  enabled: true,
                  formatter(val, opts) {
                    const label = splitHnoName(opts.w.config.series[opts.seriesIndex].name);
                    return label;
                  },
                  offsetY: 10,
                  style: {
                    colors: ['#333'],
                    fontWeight: 'normal',
                    fontSize: 10,
                  },
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    dataLabels: {
                      position: 'top',
                      hideOverflowingLabels: true,
                      orientation: 'vertical',
                    },
                  },
                },
                legend: {
                  show: true,
                  markers: {
                    width: 0,
                    height: 0,
                  },
                  onItemClick: {
                    toggleDataSeries: true,
                  },
                },
                tooltip: {
                  intersect: false,
                  shared: true,
                  followCursor: true,
                  marker: {
                    show: false,
                  },
                },
                annotations: {
                  yaxis: [
                    {
                      x: 0,
                      strokeDashArray: 0,
                      borderColor: '#666',
                      borderWidth: 1,
                      opacity: 1,
                    },
                  ],
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: true,
                    },
                  },
                },
              }
            }
            series={transformedDataByRca}
            type="bar"
            width="100%"
            height="100%"
          />
        </ChartContainer>
      </Box>
      <CollapsedContent collapsedLabel="View Data Table" openLabel="Hide Data Table">
        <Box border="1px solid" borderColor="gray.100" my={4}>
          <TableContainer>
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th position="sticky" left="0" background="gray.50" py={4} whiteSpace="nowrap" width="1px">Trend (% of DSI per year)</Th>
                  {filteredCategories.map((cat, i) => (
                    <Th key={`cat_${i}`} color={getContrastColor(theme.colors.onfCategory[cat.description].base)} background={theme.colors.onfCategory[cat.description].base}>
                      {cat.description}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {transformedDataByRca.map((network) => (
                  <Tr key={network.name}>
                    <Td position="sticky" left="0" background="gray.50">{network.name}</Td>
                    {
                    filteredCategories.map((cat, i) => (
                      <Td key={`cat_${i}`} color={network.data.find((item) => item.x === cat.description)?.trendPct > 0 ? red : green}>
                        {network.data.find((item) => item.x === cat.description)?.trendPct}
                        %
                      </Td>
                    ))
                  }
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </CollapsedContent>
    </>
  );
}
