import {
  Circle, Icon, Spinner, Text, Tooltip, VStack,
} from '@chakra-ui/react';
import { useFetchJobRerunRequired } from '@src/modules/tce/src/context/jobs';
import { FiAlertTriangle, FiCheck } from 'react-icons/fi';

export function Step({
  label, status, onClick, index, isActive, errorText,
}) {
  const { data: rerunRequiredData } = useFetchJobRerunRequired();
  let colorScheme = 'gray.200';
  let innerComponent = (
    <Text
      fontSize="xl"
      color="white"
    >
      {index + 1}
    </Text>
  );

  if (isActive) {
    colorScheme = 'brand.blue.600';
  } else if (status === 'loading') {
    innerComponent = (
      <Spinner color="white" size="sm" />
    );
  } else if (status === 'error') {
    colorScheme = 'red.600';
    innerComponent = (
      // <Tooltip label={errorText} placement="bottom-end">
      <Icon boxSize={5} as={FiAlertTriangle} color="white" />
      // </Tooltip>
    );
  } else if (status === 'complete') {
    colorScheme = 'green.600';
    innerComponent = (
      <Icon boxSize={5} as={FiCheck} color="white" />
    );
  }

  return (
    <VStack
      width="80px"
      transition="all 0.2s"
      cursor={isActive ? 'default' : 'pointer'}
      onClick={onClick}
      _hover={{
        opacity: isActive ? 1 : 0.8,
      }}
    >
      <Tooltip
        bg="red.600"
        isDisabled={status !== 'error'}
        // isDisabled={!isError}
        label={errorText}
        placement="bottom"
      >
        <Circle
          size="40px"
          bg={colorScheme}
          mx={8}
        >
          {innerComponent}
        </Circle>
      </Tooltip>
      <Text
        textAlign="center"
        whiteSpace="nowrap"
        fontSize="sm"
        fontWeight="bold"
        cursor={isActive ? 'default' : 'pointer'}
      >
        {label}
        {index === 3 && rerunRequiredData && rerunRequiredData.rerunRequired && (
          <Text color="gray.600" fontSize="xs" lineHeight="1.1" fontWeight="normal">
            Rerun Required
          </Text>
        )}
      </Text>
    </VStack>
  );
}
