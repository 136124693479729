import { useEffect, useState } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import LockYearBadge from '@src/modules/performance/components/LockYearBadge';
import DoubleAxisBarLineChart from '../charts/DoubleAxisBarLineChart';

function SLDualAxisBarLineChart({
  queryInfo, title1, title2, labels, vertlabels, colours, unitprefix, unitpostfix, precision, width, chartType, quarterFormat = 'month', ...props
}) {
  const [chart, setChart] = useState({
    grouping: [],
    datasets: [],
    units: unitpostfix || [],
  });

  useEffect(() => {
    if (queryInfo?.data) {
      const { data } = queryInfo;
      const postfixes = unitpostfix || [];
      if (data.units) {
        data.units.forEach((d, index) => {
          if (d) {
            postfixes[index] = d;
          }
        });
      }

      setChart({
        grouping: data.categories,
        datasets: data.values,
        units: postfixes,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInfo]);

  if (!queryInfo?.data) {
    return null;
  }

  return (
    <Box>
      <Text
        align="center"
        fontSize={12}
        fontWeight="bold"
        className="chart-title"
      >
        {title1}
      </Text>
      <Text
        align="center"
        fontSize={12}
        fontWeight="bold"
        mb={5}
        className="chart-title"
      >
        {title2}
      </Text>
      <LockYearBadge lockYear={queryInfo?.data?.lockYear} quarter={queryInfo?.data.quarter} quarterFormat={quarterFormat} />
      <Center>
        <DoubleAxisBarLineChart
          grouping={chart.grouping}
          labels={labels}
          vertlabels={vertlabels}
          datasets={chart.datasets}
          colours={colours}
          unitprefix={unitprefix}
          unitpostfix={chart.units}
          precision={precision}
          width={width}
          chartType={chartType}
          {...props}
        />
      </Center>
    </Box>
  );
}

export default SLDualAxisBarLineChart;
