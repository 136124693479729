import { useAuthenticatedApi } from '@transport-insights/uikit';
import { useConfig } from '@src/context/config';

export default () => {
  const { PERFORMANCE_API_URL } = useConfig();
  const api = useAuthenticatedApi(PERFORMANCE_API_URL);

  return {
    async getPotholesList(rcaId) {
      const url = `/potholes/${rcaId}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async createPotholesResult(potholeResult) {
      const { isTestMode } = potholeResult;
      delete potholeResult.isTestMode;
      const res = await api.post(`/potholes${isTestMode ? '?force=true' : ''}`, { ...potholeResult }, {
        responseType: 'json',
      });
      return res?.data || null;
    },
    async updatePotholesResult(potholeResult) {
      const { isTestMode } = potholeResult;
      delete potholeResult.isTestMode;
      const res = await api.post(`/potholes${isTestMode ? '?force=true' : ''}`, { ...potholeResult }, {
        responseType: 'json',
      });
      return res?.data || null;
    },
    async getOnrcCategories(rcaId) {
      const url = `/onrc-categories/${rcaId}`;
      const res = await api.get(url);
      return res?.data || [];
    },
  };
};
