import {
  HStack,
  Link,
} from '@chakra-ui/react';
import { useIsUserInRole, selectedDatabaseIdState } from '@transport-insights/uikit';
import { useRecoilValue } from 'recoil';
import { Link as RouterLink, Redirect } from 'react-router-dom';

const tabStyle = {
  fontWeight: 'bold',
  backgroundColor: 'transparent',
  borderBottom: '2px solid',
  borderColor: 'transparent',
  color: 'gray.600',
  marginBottom: '-2px!important',
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 3,
  paddingRight: 3,
  height: 'auto',
  fontSize: 'sm',
  lineHeight: '1.5',
  transition: 'all 0.2s ease-in-out',
  borderRadius: 0,
  _focus: {
    boxShadow: 'none',
  },
  _hover: {
    background: 'gray.100',
  },
};

const activeTabStyle = {
  ...tabStyle,
  borderColor: 'brand.orange.500',
  color: 'gray.800',
  outline: 'none',
  _hover: {
    background: 'transparent',
    cursor: 'default',
  },
};

export default function MainNav() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const hasDeveloperRole = useIsUserInRole('Developer');
  const hasTtmAdminRole = useIsUserInRole('TTMAdmin');
  const hasRcaUserRole = useIsUserInRole(`db:${rcaId}:User`);
  const hasRcaAdminRole = useIsUserInRole(`db:${rcaId}:Admin`);
  const hasRcaRole = hasRcaUserRole || hasRcaAdminRole;
  const currentUrl = window.location.pathname.split('/').pop();

  // PMRT-7447: Redirect TTMAdmin to admin-report if they don't have RCA role
  if (!hasRcaRole && !hasDeveloperRole && hasTtmAdminRole) {
    return (
      <Redirect to="/performance/quarterly-reporting/admin-report" />
    );
  }

  return (
    <HStack
      mx={4}
      mb={6}
      borderBottom="2px solid"
      borderColor="gray.100"
      spacing={2}
    >
      {(hasDeveloperRole || hasRcaRole) && (
        <>
          <Link
            variant="unstyled"
            as={RouterLink}
            to="temporary-traffic-management"
            sx={currentUrl === 'temporary-traffic-management' ? activeTabStyle : tabStyle}
          >
            Temporary Traffic Management
          </Link>
          <Link
            variant="unstyled"
            as={RouterLink}
            to="potholes"
            sx={currentUrl === 'potholes' ? activeTabStyle : tabStyle}
          >
            Potholes
          </Link>
        </>
      )}
      {(hasTtmAdminRole || hasDeveloperRole) && (
        <Link
          as={RouterLink}
          to="admin-report"
          variant="unstyled"
          sx={currentUrl === 'admin-report' ? activeTabStyle : tabStyle}
        >
          Admin Report
        </Link>
      )}
    </HStack>
  );
}
