import {
  Checkbox, HStack, Text,
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import {
  tceWizardFilterOnlyInconsistentTrafficMix,
  tceWizardFilterOnlySignificantChanges,
} from '@tce/state/tce';
import { useJob } from '@src/modules/tce/src/context/jobs';
import { useEffect } from 'react';

function Step1Filter({ name }) {
  const { job } = useJob();
  const [onlyInconsistentTrafficMix, setOnlyInconsistentTrafficMix] = useRecoilState(tceWizardFilterOnlyInconsistentTrafficMix(name));
  const onChangeOnlyInconsistentTrafficMix = () => setOnlyInconsistentTrafficMix(!onlyInconsistentTrafficMix);

  const [onlySignificantChanges, setOnlySignificantChanges] = useRecoilState(tceWizardFilterOnlySignificantChanges(name));

  const onChangeOnlySignificantChanges = () => setOnlySignificantChanges(!onlySignificantChanges);

  useEffect(
    () => {
      if (job && job.status === 'RESULTS') {
        setOnlySignificantChanges(false);
        setOnlyInconsistentTrafficMix(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [job],
  );

  return (
    <HStack w="full" align="center" spacing={4}>
      <Checkbox
        colorScheme="blue"
        isChecked={onlySignificantChanges}
        onChange={(e) => onChangeOnlySignificantChanges(e)}
      >
        <Text as="label" fontSize="sm">Show significant changes in Count (+/- 15% or 100vpd)</Text>
      </Checkbox>
      <Checkbox
        colorScheme="blue"
        isChecked={onlyInconsistentTrafficMix}
        onChange={(e) => onChangeOnlyInconsistentTrafficMix(e)}
      >
        <Text as="label" fontSize="sm">Show Counts with an inconsistent Traffic Mix</Text>
      </Checkbox>
    </HStack>
  );
}

export {
  Step1Filter,
};
