import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
  useTheme,
  Text,
  GridItem,
  Grid,
  SimpleGrid,
} from '@chakra-ui/react';
import { groupBy } from 'lodash';
import Chart from 'react-apexcharts';
import {
  convertBubbleRange,
  roundToDecimal,
  currencyFormatter,
  getTrendDirection,
  getRangeOfPoints,
} from '@src/utils/math';
import {
  getContrastColor, COMPARISON_COLORS, getTrendlineColor,
} from '@src/utils/color';
import { useFilteredCategories } from '../../hooks/useFilteredCategories';
import ReportSubheading from '../shared/ReportSubheading';
import CollapsedContent from '../shared/CollapsedContent';
import ChartContainer from '../shared/ChartContainer';
import { useTrendSvg } from '../../hooks/useTrendSvg';

const measureMap = {
  Cost: 'Cost',
  Length: 'Lane km',
  AvgLife: 'Average Life',
  Area: 'Area',
};

export default function Ep4({
  filters, selectedToggleOption, reportData, networkSelector,
}) {
  const theme = useTheme();
  const { upGraySvg, downGraySvg, flatSvg } = useTrendSvg();
  const filteredCategories = useFilteredCategories(filters);
  const years = [...new Set(reportData.data.map((x) => x.financialYear))].sort();
  const rca = reportData.rcas[0];
  const selectedMeasure = selectedToggleOption?.subtype ? measureMap[selectedToggleOption?.subtype[0]] : 'Lane km';
  const filteredReportData = reportData.data
    .filter((x) => x.costGroup !== null)
    .filter((x) => Object.entries(selectedToggleOption).every(([k, v]) => v.includes(x[k])));
  const selectedRcaFilteredData = filteredReportData.filter((x) => x.id === rca.id);
  const pieChartData = selectedRcaFilteredData.filter((x) => x.financialYear === years.at(-1))
    .filter((x) => x.value > 0)
    .map((item) => ({
      category: item.category,
      value: roundToDecimal(item.value, 2),
    }));
  const currentYearOverview = selectedRcaFilteredData.filter((x) => x.financialYear === years.at(-1))
    .map((item) => ({
      category: item.category,
      value: roundToDecimal(item.value, 2),
      trendM: item.trendM,
      length: roundToDecimal(item.networkLengthKm, 1),
      trendDirection: getTrendDirection(roundToDecimal(item.trendM, 3), roundToDecimal(item.trendM, 3), item.trendC),
    }));
  const bubbleChartData = Object.entries(groupBy(selectedRcaFilteredData.filter((x) => x.financialYear === years.at(-1)), 'category'))
    .map(([k, v]) => ({
      category: k,
      value: v.map((item) => item.value).reduce((a, b) => a + b, 0),
      length: roundToDecimal(v[0].networkLengthKm, 1),
      networkVKT: roundToDecimal(v[0].networkVKT, 1),
    }));
  const transformedDataByCategory = Object.entries(groupBy(selectedRcaFilteredData, 'category'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        year: item.financialYear,
        value: item.value,
        trendY1: item.trendB,
        trendY2: item.trendB + (item.trendM * item.trendC),
      })),
    }));
  const transformedDataByRca = Object.entries(groupBy(filteredReportData.filter((x) => x.financialYear === years.at(-1)), 'rcaName'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        x: item.category,
        y: item.value,
      })),
    }));
  const dataTable = Object.entries(groupBy(reportData.data, 'category'))
    .map(([k, v]) => ({
      category: k,
      data: v.map((item) => ({
        year: item.financialYear,
        rcaName: item.rcaName,
        reportType: item.report_type,
        subtype: item.subtype,
        value: item.value,
      })),
    }))
    .map((x) => ({
      name: x.category,
      data: Object.entries(groupBy(x.data, 'rcaName'))
        .map(([k, v]) => ({
          rcaName: k,
          data: Object.entries(groupBy(v, 'year')).map(([year, val]) => ({
            year,
            values: [
              val.filter(({ reportType }) => reportType === 'Asphalt Resurfacing')
                .map((y) => (
                  {
                    reportType: y.reportType,
                    [y.subtype]: y.value,
                  }))
                .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
              val.filter(({ reportType }) => reportType === 'Chipseal Resurfacing')
                .map((y) => (
                  {
                    reportType: y.reportType,
                    [y.subtype]: y.value,
                  }))
                .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
              val.filter(({ reportType }) => reportType === 'Pavement Rehabilitation')
                .map((y) => (
                  {
                    reportType: y.reportType,
                    [y.subtype]: y.value,
                  }))
                .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
            ],
          })),
        })),
    }));

  const measureFormatter = (measure, value) => {
    switch (measure) {
      case 'Cost':
        return currencyFormatter.format(value);
      case 'Lane km':
        return `${value.toFixed(1)} km`;
      case 'Average Life':
        return `${value.toFixed(1)} years`;
      case 'Area':
        return `${value.toLocaleString('en-US')} sqm`;
      default:
        return value;
    }
  };

  return (
    <>
      <Grid templateColumns={{ md: '100%', lg: '1fr 1fr', xl: '1fr 2fr' }} gap={4}>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>
            Percentage by ONF Category
          </ReportSubheading>
          <Text mb={8}>
            This chart shows the percentages of the
            {' '}
            {selectedMeasure}
            {' '}
            by ONF Street Category for the year in the network.
          </Text>
          <ChartContainer>
            <Chart
              options={
                {
                  dataLabels: {
                    enabled: true,
                  },
                  colors: pieChartData.map((x) => theme.colors.onfCategory[x.category].base),
                  legend: {
                    show: true,
                    position: 'bottom',
                  },
                  tooltip: {
                    enabled: false,
                    enabledOnSeries: [0],
                    marker: {
                      show: false,
                    },
                  },
                  labels: pieChartData.map((x) => x.category),
                }
              }
              series={pieChartData.map((x) => x.value)}
              type="pie"
              width="100%"
            />
          </ChartContainer>
        </GridItem>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>
            Measure totals by ONF Category
          </ReportSubheading>
          <Text mb={4}>
            The bars show the Cost Efficiency measure total for each ONF Street Category. The arrow above the bar shows the trend.
          </Text>
          <ChartContainer>
            <Chart
              options={
                {
                  xaxis: {
                    type: 'category',
                    categories: [...new Set(currentYearOverview.map((x) => x.category))],
                    labels: {
                      style: {
                        colors: currentYearOverview.map((x) => theme.colors.onfCategory[x.category].base),
                        fontWeight: 'bold',
                      },
                    },
                    tickPlacement: 'between',
                  },
                  yaxis: [
                    {
                      title: {
                        text: selectedMeasure,
                      },
                      labels: {
                        formatter(value) {
                          return measureFormatter(selectedMeasure, value);
                        },
                      },
                    },
                  ],
                  dataLabels: {
                    enabled: false,
                  },
                  colors: currentYearOverview.map((x) => theme.colors.onfCategory[x.category].base),
                  legend: {
                    show: false,
                  },
                  tooltip: {
                    enabled: true,
                    enabledOnSeries: [0],
                    marker: {
                      show: false,
                    },
                    custom({ seriesIndex, dataPointIndex, w }) {
                      const {
                        x, y, length,
                      } = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                      return `<div style="padding: 10px">
                      <p style="color: ${theme.colors.onfCategory[x].base}; font-weight: bold; margin-bottom: 10px;">${x}</p> 
                      <ul>
                      <li><b>${selectedMeasure}</b>: ${measureFormatter(selectedMeasure, y)}</li>
                      <li><b>Length</b>: ${length} km</li>
                      </ul></div>`;
                    },
                  },
                  plotOptions: {
                    bar: {
                      distributed: true,
                      horizontal: false,
                    },
                  },
                  annotations: {
                    points: currentYearOverview.map((item) => (
                      {
                        x: item.category,
                        y: item.value,
                        marker: {
                          size: 0,
                        },
                        customSVG: {
                          // eslint-disable-next-line no-nested-ternary
                          SVG: item.trendDirection === 'flat' ? flatSvg : item.trendDirection === 'up' ? upGraySvg : downGraySvg,
                          cssClass: undefined,
                          offsetX: -30,
                          offsetY: -50,
                        },
                      }
                    )),
                  },
                }
              }
              series={
                [
                  {
                    name: years.at(-1),
                    data: currentYearOverview.map((x) => ({
                      y: x.value, x: x.category, trend: x.trendM, length: x.length,
                    })),
                  },
                ]
              }
              type="bar"
              width="100%"
              height="400"
            />
          </ChartContainer>
        </GridItem>
      </Grid>
      <Box my={8}>
        <ReportSubheading>
          Measure totals and VKT by ONF Category
        </ReportSubheading>
        <Grid templateColumns={{ md: '100%', lg: '1fr 3fr', xl: '1fr 4fr' }} gap={4}>
          <GridItem>
            <Text mb={8}>
              This chart shows the
              {' '}
              {selectedMeasure}
              {' '}
              measure for each ONF Street Category. The bottom axis is the network length for the ONF Street Categories. The vertical axis is
              {' '}
              {selectedMeasure}
              . The size of the bubble indicates the vehice journeys (VKT) for each category.
            </Text>
          </GridItem>
          <GridItem>
            <ChartContainer minHeight="550">
              <Chart
                options={
                  {
                    colors: bubbleChartData.map((x) => theme.colors.onfCategory[x.category].base),
                    grid: {
                      padding: {
                        left: 20,
                        bottom: 50,
                      },
                    },
                    legend: {
                      offsetY: -20,
                    },
                    xaxis: {
                      type: 'numeric',
                      title: {
                        text: 'Street Category Network Length (km)',
                        offsetY: 20,
                      },
                      min: 0,
                      max: Math.max(...bubbleChartData.map((x) => x.length)) * 1.1,
                      labels: {
                        formatter(val) {
                          return `${val.toFixed(0)} km`;
                        },
                      },
                    },
                    yaxis: [
                      {
                        min: 0,
                        max: (max) => Math.round(max * 1.2),
                        title: {
                          text: selectedMeasure,
                        },
                        labels: {
                          formatter(value) {
                            return measureFormatter(selectedMeasure, value);
                          },
                        },
                      },
                    ],
                    dataLabels: {
                      enabled: false,
                    },
                    fill: {
                      opacity: 0.8,
                    },
                    tooltip: {
                      marker: {
                        show: false,
                      },
                      x: {
                        show: true,
                        formatter: (val, { seriesIndex, w }) => `${w.globals.seriesNames[seriesIndex]}: ${val.toFixed(0)} km`,
                      },
                      custom({ seriesIndex, dataPointIndex, w }) {
                        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                        const { name } = w.globals.initialSeries[seriesIndex];

                        return `<div style="padding: 10px">
                        <p style="color: ${theme.colors.onfCategory[name].base}; font-weight: bold; margin-bottom: 10px;">${name}</p> 
                        <ul>
                        <li><b>${selectedMeasure}</b>: ${measureFormatter(selectedMeasure, data.y)}</li>
                        <li><b>Network VKT</b>: ${data.networkVKT} million</li>
                        <li><b>Length</b>: ${data.x} km</li>
                        </ul></div>`;
                      },
                    },
                    plotOptions: {
                      bubble: {
                        zScaling: true,
                      },
                    },
                  }
                }
                series={bubbleChartData.map((item) => ({
                  name: item.category,
                  data: [
                    {
                      x: item.length,
                      y: item.value,
                      z: convertBubbleRange(item.networkVKT, [Math.min(...bubbleChartData.map((x) => x.networkVKT)), Math.max(...bubbleChartData.map((x) => x.networkVKT))], [1, 8]),
                      networkVKT: item.networkVKT,
                    }],
                }))}
                type="bubble"
                width="100%"
                height="100%"
              />
            </ChartContainer>
          </GridItem>
        </Grid>
      </Box>
      <Box my={8}>
        <ReportSubheading>ONF Street Category trends</ReportSubheading>
        <Text mb={8}>These graph shows the trend in Cost Efficiency, for each ONF Street Category.</Text>
        <SimpleGrid columns={[2, 2, 4, 4, 6, 6]} spacing={2}>
          {transformedDataByCategory.map((cat) => (
            <GridItem
              as={ChartContainer}
              key={`cat_${cat.name}`}
              p={2}
              minH="280"
            >
              <Chart
                options={
                  {
                    title: {
                      text: cat.name,
                      style: {
                        color: theme.colors.onfCategory[cat.name].base,
                      },
                    },
                    xaxis: {
                      categories: years,
                    },
                    yaxis: [
                      {
                        min: 0,
                        max: Math.max(...transformedDataByCategory.flatMap((x) => x.data.map((y) => y.value))),
                        forceNiceScale: true,
                        title: {
                          text: selectedMeasure,
                        },
                        labels: {
                          formatter(value) {
                            return measureFormatter(selectedMeasure, value);
                          },
                        },
                      },
                    ],
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: 'straight',
                      width: [0, 4],
                    },
                    colors: [getTrendlineColor(cat.data.at(-1).trendY1, cat.data.at(-1).trendY2, false, true)],
                    fill: {
                      colors: [theme.colors.onfCategory[cat.name].base],
                    },
                    markers: {
                      size: 0,
                      hover: {
                        size: 0,
                      },
                    },
                    legend: {
                      show: false,
                    },
                    tooltip: {
                      enabled: true,
                      enabledOnSeries: [0],
                      marker: {
                        show: false,
                      },
                    },
                  }
                }
                series={
                  [
                    {
                      name: cat.name,
                      type: 'column',
                      data: cat.data.map((x) => x.value),
                    },
                    {
                      name: 'Trend',
                      type: 'line',
                      data: getRangeOfPoints(cat.data.at(-1).trendY1, cat.data.at(-1).trendY2, years.length),
                    },
                  ]
                }
                type="line"
                width="100%"
                height="100%"
              />
            </GridItem>
          ))}
        </SimpleGrid>
      </Box>
      {networkSelector}
      <Box my={8}>
        <ReportSubheading>
          Compared to other networks
        </ReportSubheading>
        <Text mb={8}>
          This graph shows the Cost Efficiency for each ONF Street Category, for your network and the other networks you are comparing with (defaults to the Peer Group and Region of the RCA, and National).
        </Text>
        <ChartContainer>
          <Chart
            options={
              {
                stroke: {
                  colors: ['transparent'],
                  width: 1,
                },
                colors: [rca.rcaBrandColour, ...COMPARISON_COLORS],
                yaxis: [
                  {
                    forceNiceScale: true,
                    tickAmount: 8,
                    title: {
                      text: selectedMeasure,
                    },
                    labels: {
                      formatter(value) {
                        return measureFormatter(selectedMeasure, value);
                      },
                    },
                  },
                ],
                xaxis: {
                  categories: filteredCategories.map((cat) => cat.description),
                  labels: {
                    style: {
                      colors: filteredCategories.map((cat) => theme.colors.onfCategory[cat.description].base),
                      fontWeight: 'bold',
                    },
                  },
                  tickPlacement: 'between',
                },
                tooltip: {
                  intersect: false,
                  shared: true,
                  followCursor: true,
                  marker: {
                    show: true,
                  },
                },
                dataLabels: {
                  enabled: false,
                  formatter: (value) => (value === 0 ? null : measureFormatter(selectedMeasure, value)),
                },
                legend: {
                  onItemClick: {
                    toggleDataSeries: false,
                  },
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: true,
                    },
                  },
                },
              }
            }
            series={transformedDataByRca}
            type="bar"
            width="100%"
            height="500"
          />
        </ChartContainer>
      </Box>
      <CollapsedContent collapsedLabel="View Data Table" openLabel="Hide Data Table">
        <Box border="1px solid" borderColor="gray.100" my={4}>
          <TableContainer>
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th position="sticky" left="0" background="gray.50" py={4} whiteSpace="nowrap" width="1px">
                    Category
                  </Th>
                  <Th background="gray.50">Network</Th>
                  <Th background="gray.50">Year</Th>
                  <Th background="gray.50">Report</Th>
                  <Th background="gray.50">Lane km</Th>
                  <Th background="gray.50">Area</Th>
                  <Th background="gray.50">Average Life</Th>
                  <Th background="gray.50">Cost</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dataTable.map((category, idx1) => category.data.map(({ rcaName, data }, idx2) => data.map(({
                  year, values,
                }, idx3) => values.map(({
                  reportType, Length, Cost, Area, AvgLife,
                }, idx4) => (
                  <Tr key={`row_${idx1}_${idx2}_${idx3}_${idx4}`}>
                    {idx2 === 0 && idx3 === 0 && idx4 === 0
                      && (
                      <Td
                        position="sticky"
                        left="0"
                        color={getContrastColor(theme.colors.onfCategory[category.name].base)}
                        background={theme.colors.onfCategory[category.name].base}
                        rowSpan={category.data.length * (data.length * values.length)}
                        borderBottom="2px solid"
                        borderColor={idx2 === 0 ? theme.colors.onfCategory[category.name].base : 'gray.100'}
                        fontWeight="bold"
                      >
                        {category.name}
                      </Td>
                      )}

                    {idx3 === 0 && idx4 === 0
                      && (
                      <Td
                        rowSpan={data.length * values.length}
                        borderBottom="2px solid"
                        borderColor="gray.100"
                      >
                        {rcaName}
                      </Td>
                      )}
                    {idx4 === 0
                      && (
                      <Td
                        rowSpan={values.length}
                        borderBottom="2px solid"
                        borderColor="gray.100"
                      >
                        {year}
                      </Td>
                      )}
                    <Td
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {reportType}
                    </Td>
                    <Td
                      key={`cat_${idx4}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {measureFormatter('Lane km', Length)}
                    </Td>
                    <Td
                      key={`cat_${idx4}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {measureFormatter('Area', Area)}
                    </Td>
                    <Td
                      key={`cat_${idx4}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {measureFormatter('Average Life', AvgLife)}
                    </Td>
                    <Td
                      key={`cat_${idx4}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {measureFormatter('Cost', Cost)}
                    </Td>
                  </Tr>
                )))))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </CollapsedContent>
    </>
  );
}
