import {
  Container, SimpleGrid, Heading,
  useDisclosure,
  HStack,
  VStack,
  Text,
  Box,
  Button,
  Stack,
} from '@chakra-ui/react';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import EmptyState from '@src/components/shared/EmptyState';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedDatabaseIdState, useIsUserInRole } from '@transport-insights/uikit';
import { QUARTERS } from '@src/modules/performance/shared/constants';
import { useCurrentTtmEntryPeriod, useTtmList } from '../../../context/temporary-traffic-management-api-hooks';
import TtmCard from './components/TtmCard';
import TtmResultForm from './components/TtmResultForm';
import { calculateProgress } from '../shared/helpers';
import DevTools from '../shared/components/DevTools';
import TtmSiteInspectionForm from './components/TtmSiteInspectionForm';
import TtmSiteInspectionView from './components/TtmSiteInspectionView';

export default function TtmResults() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const { data, isLoading } = useTtmList();
  const { data: currentEntryPeriod, error: entryPeriodError } = useCurrentTtmEntryPeriod();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isSiteInspectionFormOpen, onOpen: onOpenSiteInspectionForm, onClose: onCloseSiteInspectionForm } = useDisclosure();
  const { isOpen: isSiteInspectionViewOpen, onOpen: onOpenSiteInspectionView, onClose: onCloseSiteInspectionView } = useDisclosure();
  const [selectedEntry, setSelectedEntry] = useState(null);
  const errorStatus = entryPeriodError?.response?.status;
  const [isTestMode, setIsTestMode] = useState(false);
  const [groupedData, setGroupedData] = useState({});
  const hasDeveloperRole = useIsUserInRole('Developer');
  const hasTTMSiteCaptureRole = useIsUserInRole(`db:${rcaId}:TTMSiteCapture`);

  // Create an empty entry for the current period
  const currentEntry = {
    id: null,
    quadDatabaseId: rcaId,
    quadLockYear: currentEntryPeriod?.lockYear,
    quarter: currentEntryPeriod?.quarter,
    ttmCostConfidence: null,
    ttmCost: null,
    nltpCost: null,
    unattendedSiteInspections: null,
    completedSiteInspections: null,
  };

  // Combine the current entry with the existing data
  const allEntries = [...(data || []), currentEntry];

  const dataYears = [...new Set(allEntries.map((item) => item?.quadLockYear))]
    .sort((a, b) => b.localeCompare(a, undefined, { numeric: true, sensitivity: 'base' }));

  // Group the data by year and quarter
  // and insert the calculated progress and phase
  const handleGroupData = () => {
    const grouped = dataYears.reduce((acc, year) => {
      const yearEntries = QUARTERS.map((quarter) => {
        const entry = allEntries.find(
          (item) => item.quadLockYear === year && item.quarter === quarter.id,
        ) || {
          id: null,
          quadDatabaseId: rcaId,
          quadLockYear: year,
          quarter: quarter.id,
          ttmCostConfidence: null,
          ttmCost: null,
          nltpCost: null,
          unattendedSiteInspections: null,
          completedSiteInspections: null,
        };

        const {
          progress, phase, daysUntilEnd, daysUntilStart,
        } = calculateProgress(currentEntryPeriod?.entryStartDate, currentEntryPeriod?.entryEndDate);

        const isFutureQuarter = year > currentEntryPeriod?.lockYear || (year === currentEntryPeriod?.lockYear && quarter.id > currentEntryPeriod?.quarter);
        const isPastQuarter = year < currentEntryPeriod?.lockYear || (year === currentEntryPeriod?.lockYear && quarter.id < currentEntryPeriod?.quarter);
        const isCurrentQuarter = year === currentEntryPeriod?.lockYear && quarter.id === currentEntryPeriod?.quarter;

        const isEditable = isCurrentQuarter;

        return {
          ...entry,
          isFutureQuarter,
          isPastQuarter,
          isEditable,
          progress,
          phase,
          daysUntilEnd,
          daysUntilStart,
          entryStartDate: currentEntryPeriod?.entryStartDate,
          entryEndDate: currentEntryPeriod?.entryEndDate,
        };
      });
      acc[year] = yearEntries;
      return acc;
    }, {});
    setGroupedData(grouped);
  };

  useEffect(() => {
    handleGroupData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, currentEntryPeriod, rcaId]);

  const handleSelectEntry = (item) => {
    if (item.isEditable) {
      setSelectedEntry(item);
      onOpen();
    }
  };

  const handleCloseResultForm = () => {
    setSelectedEntry(null);
    onClose();
  };

  if ([401, 403, 500].includes(errorStatus)) {
    return (
      <Container maxW="full" flexDirection="column" display="flex" flexGrow={1}>
        <HStack mb={6} width="100%" justify="space-between">
          <Heading as="h1">Temporary Traffic Management</Heading>
        </HStack>
        <EmptyState
          title="Access Denied"
          message={(
            <>
              <Text color="gray.400">You do not have permission to view this page.</Text>
              <Text color="gray.400">Please sign in or select a different RCA.</Text>
            </>
          )}
        />
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Container maxW="full" flexDirection="column" display="flex" flexGrow={1}>
        <HStack mb={6} width="100%" justify="space-between">
          <Heading as="h1">Temporary Traffic Management</Heading>
        </HStack>
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <>
      {selectedEntry && (
        <TtmResultForm isOpen={isOpen} onClose={() => handleCloseResultForm()} selectedEntry={selectedEntry} isTestMode={isTestMode} />
      )}
      <TtmSiteInspectionView isOpen={isSiteInspectionViewOpen} onClose={onCloseSiteInspectionView} />
      {(hasDeveloperRole || hasTTMSiteCaptureRole) && (
        <TtmSiteInspectionForm isOpen={isSiteInspectionFormOpen} onClose={onCloseSiteInspectionForm} />
      )}
      <Container maxW="full" flexDirection="column" display="flex" flexGrow={1}>
        <Stack direction={{ base: 'column', md: 'row' }} mb={6} width="100%" justify="space-between">
          <Heading as="h1">Temporary Traffic Management</Heading>
          <HStack>
            <Button onClick={onOpenSiteInspectionView} variant="ghost" size="md">View Site Inspections</Button>
            {(hasDeveloperRole || hasTTMSiteCaptureRole) && (
              <Button onClick={onOpenSiteInspectionForm} size="md">Add Site Inspection</Button>
            )}
          </HStack>
        </Stack>
        <DevTools onChangeMockDate={handleGroupData} onChangeTestMode={(v) => setIsTestMode(v)} />
        {!Object.keys(groupedData).length ? (
          <EmptyState title="No Data" message="Sorry, there is no data available for the selected network." />
        ) : (
          <VStack align="flex-start" width="100%">
            {Object.keys(groupedData).map((year) => (
              <Box key={year} width="100%">
                <Heading as="h2" size="md" py={2} px={4} background="gray.100" borderRadius="full" display="inline-block" mb={2}>{year}</Heading>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={4} width="100%" py={2}>
                  {groupedData[year].sort((a, b) => a.quarter - b.quarter)
                    .map((item, indx) => (
                      <TtmCard
                        key={indx}
                        item={item}
                        onEdit={() => handleSelectEntry(item)}
                      />
                    ))}
                </SimpleGrid>
              </Box>
            ))}
          </VStack>
        )}
      </Container>
    </>
  );
}
