import {
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Spacer,
  VStack,
  Icon,
} from '@chakra-ui/react';
import { FiSearch, FiX } from 'react-icons/fi';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useRoadSectionCategories, useRoadSectionHierarchies } from '@src/modules/tce/src/context/roadsections';
import {
  tceWizardFilterCwayHierarchyState,
  tceWizardFilterOnrcCategoryState,
  tceWizardFilterRoadState,
  tceWizardFilterTrafficGroupState,
  tceWizardFilterUrbanRuralState,
} from '@tce/state/tce';
import debouncePromise from 'awesome-debounce-promise';
import { useTceTrafficGroups } from '@tce/context/trafficgroups';
import { useRef } from 'react';

export default function Filter({ children, name }) {
  const [filterRoad, setFilterRoad] = useRecoilState(tceWizardFilterRoadState(name));
  const [filterTrafficGroup, setFilterTrafficGroup] = useRecoilState(tceWizardFilterTrafficGroupState(name));
  const [filterCategory, setFilterCategory] = useRecoilState(tceWizardFilterOnrcCategoryState(name));
  const setFilterHierarchy = useSetRecoilState(tceWizardFilterCwayHierarchyState(name));
  const setFilterUrbanRural = useSetRecoilState(tceWizardFilterUrbanRuralState(name));
  const { isLoading: loadedCategories, categories } = useRoadSectionCategories();
  const { isLoading: loadedHierarchies, hierarchies } = useRoadSectionHierarchies();
  const { isLoading: loadedTrafficGroups, trafficgroups } = useTceTrafficGroups();
  const roadNameRef = useRef(null);
  const urbanRural = ['Urban', 'Rural'];

  const triggerRoadFilterChange = (roadName) => {
    setFilterRoad(roadName);
  };
  const triggerRoadFilterChangeDebounced = debouncePromise(triggerRoadFilterChange, 500);

  const onFilterChange = (value, type) => {
    if (type === 'road') {
      setFilterRoad(value === '' ? null : value);
    } else if (type === 'category') {
      setFilterCategory(parseInt(value, 10));
    } else if (type === 'trafficGroup') {
      setFilterTrafficGroup(value);
    } else if (type === 'hierarchy') {
      setFilterHierarchy(value);
    } else if (type === 'urbanRural') {
      let urbanRuralValue = value;
      if (value === 'Urban') {
        urbanRuralValue = 'U';
      } else if (value === 'Rural') {
        urbanRuralValue = 'R';
      }
      setFilterUrbanRural(urbanRuralValue);
    }
  };

  const onClearRoadName = () => {
    roadNameRef.current.value = '';
    onFilterChange('', 'road');
  };

  return (
    <VStack my="4">
      <HStack w="full">
        <InputGroup flex={1} size="sm">
          <InputLeftElement pointerEvents="none">
            <Icon as={FiSearch} color="gray.300" />
          </InputLeftElement>
          <Input
            type="tel"
            placeholder="Filter by road name..."
            defaultValue={filterRoad}
            onChange={async (e) => triggerRoadFilterChangeDebounced(e.target.value)}
            ref={roadNameRef}
          />
          <InputRightElement>
            <IconButton
              onClick={(e) => onClearRoadName(e)}
              colorScheme="white"
              icon={<Icon as={FiX} color="gray.300" />}
              size="sm"
            />
          </InputRightElement>
        </InputGroup>
        <InputGroup flex={3} size="sm">
          <Select
            bgGradient="linear(to-b, gray.50, gray.100)"
            placeholder="Traffic Group"
            value={filterTrafficGroup ?? ''}
            onChange={(e) => onFilterChange(e.target.value, 'trafficGroup')}
            mr="2"
          >
            {!loadedTrafficGroups && trafficgroups?.map((group) => (
              <option key={group.id} value={group.id ?? null} style={{ color: 'black' }}>
                {group.code}
              </option>
            ))}
          </Select>
          <Select
            bgGradient="linear(to-b, gray.50, gray.100)"
            placeholder="ONRC Category"
            value={filterCategory ?? ''}
            onChange={(e) => onFilterChange(e.target.value, 'category')}
            mr="2"
          >
            {!loadedCategories && categories?.map((cat) => (
              <option key={cat.id} value={cat.id ?? null} style={{ color: 'black' }}>
                {cat.name}
              </option>
            ))}
          </Select>
          <Select
            bgGradient="linear(to-b, gray.50, gray.100)"
            placeholder="Cway Hierarchy"
            onChange={(e) => onFilterChange(e.target.value, 'hierarchy')}
            mr="2"
          >
            {!loadedHierarchies && hierarchies?.map((h) => (
              <option key={h} value={h ?? null} style={{ color: 'black' }}>
                {h}
              </option>
            ))}
          </Select>
          <Select
            bgGradient="linear(to-b, gray.50, gray.100)"
            placeholder="Urban & Rural"
            onChange={(e) => onFilterChange(e.target.value, 'urbanRural')}
          >
            {urbanRural.map((ur) => (
              <option key={ur} value={ur ?? null} style={{ color: 'black' }}>
                {ur}
              </option>
            ))}
          </Select>
        </InputGroup>
      </HStack>
      {children && (
      <HStack w="full" mt={4}>
        {children}
        <Spacer />
      </HStack>
      )}
    </VStack>
  );
}
