import { Checkbox, HStack, Text } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { tceWizardFilterOnlyMissingAADT } from '@tce/state/tce';
import { useEffect } from 'react';
import { useJob } from '@src/modules/tce/src/context/jobs';

function Step2Filter({ name }) {
  const { job } = useJob();
  const [onlyMissingAADT, setOnlyMissingAADT] = useRecoilState(
    tceWizardFilterOnlyMissingAADT(name),
  );

  const onChangeOnlyMissingAADT = () => setOnlyMissingAADT(!onlyMissingAADT);

  useEffect(
    () => {
      if (job && job.status === 'RESULTS') {
        setOnlyMissingAADT(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [job],
  );

  return (
    <HStack w="full">
      <Checkbox
        colorScheme="blue"
        isChecked={onlyMissingAADT}
        onChange={(e) => onChangeOnlyMissingAADT(e)}
      >
        <Text as="label" fontSize="sm">Only show carriageways with zero or no AADT</Text>
      </Checkbox>
    </HStack>
  );
}

export {
  Step2Filter,
};
