import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem,
  AccordionPanel, Box, Button, Flex, HStack, Icon, Progress, Spacer, Spinner, Text, VStack,
} from '@chakra-ui/react';
import { CalculateRates, PollSingleJob, useRerunJob } from '@src/modules/tce/src/context/jobs';
import { selectedJobIdState } from '@src/modules/tce/src/state/tce';
import { FiCheckCircle } from 'react-icons/fi';
import { Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

function Step4({ canRunCalculation, onClick }) {
  const { job } = PollSingleJob();
  const jobId = useRecoilValue(selectedJobIdState);
  const { mutate: calculateTrafficGroupRates } = CalculateRates({});
  const { data: newJob, mutate: doRerunJob, isLoading: isRerunningJob } = useRerunJob({});

  const startTheJob = () => {
    calculateTrafficGroupRates(jobId);
  };

  const handleRerunJob = async () => {
    await doRerunJob(jobId);
  };

  if (newJob) {
    return (
      <Redirect to={`/tce/estimates/${newJob.id}`} />
    );
  }

  if (!job) {
    return (
      <Flex
        background="gray.50"
        p={12}
        borderRadius="md"
        flexGrow="1"
        align="center"
      >
        <VStack fontSize="20px">
          <Spinner />
        </VStack>
      </Flex>
    );
  }

  if (!canRunCalculation) {
    return (
      <Flex
        background="gray.50"
        p={12}
        borderRadius="md"
        flexGrow="1"
        align="center"
      >
        <VStack fontSize="20px">
          <Text fontSize="20px">
            Please complete the previous steps before calculating the Traffic Count Estimates.
          </Text>
        </VStack>
      </Flex>
    );
  }

  return (
    <Flex
      background="gray.50"
      p={12}
      borderRadius="md"
      flexGrow="1"
      align="center"
    >
      {job.status === 'RESULTS' && (
        <VStack fontSize="20px">
          <Icon as={FiCheckCircle} color="green.600" boxSize={20} />
          <Text>The Traffic count estimation for this import has been completed.</Text>
          <Text>Go to the next page to view and export your results.</Text>
          <Spacer />
          <HStack spacing={4}>
            <Button onClick={handleRerunJob} isLoading={isRerunningJob} variant="outline">Rerun Calculation</Button>
            <Button onClick={onClick}>View Results</Button>
          </HStack>
        </VStack>
      )}
      {job.status === 'FAILED' && (
        <VStack fontSize="20px">
          <Text>There was an issue processing your estimate.</Text>
          <Text>Please contact Reg Insights support.</Text>
          <Accordion allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Details
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {job.errorMessage}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </VStack>
      )}
      {job.status !== 'RESULTS' && job.status !== 'FAILED' && job.status !== 'READY' && (
        <VStack fontSize="20px">
          <Text>The Traffic count estimation for this import is being calculated.</Text>
          <Spacer />
          <Progress
            width="100%"
            value={job.percentCompleted}
            colorScheme="orange"
            size="xs"
            backgroundColor="gray.100"
            isIndeterminate
          />
          <Text color="gray.500" fontSize="16px">
            {job.status}
            {' '}
            <span>
              {job.percentCompleted}
              %
            </span>
          </Text>
        </VStack>
      )}
      {job.status === 'READY' && (
        <VStack fontSize="20px">
          <Text>The Traffic count estimation for this import is ready to be calculated.</Text>
          <Spacer />
          <Button onClick={startTheJob}>Start the calculation</Button>
        </VStack>
      )}
    </Flex>
  );
}

export default Step4;
