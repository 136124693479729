import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  ButtonGroup,
  HStack,
  VStack,
  Input,
  Box,
  InputRightAddon,
  InputGroup,
  Heading,
  Checkbox,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { QUARTERS } from '@src/modules/performance/shared/constants';
import { useCreatePotholesResult, useUpdatePotholesResult } from '../../../../context/potholes-api-hooks';
import { calculatePercentage, formatTimeAsHumanReadable } from '../../shared/helpers';

const schema = yup.object().shape({
  losAchievedConfidence: yup.number().required('Confidence in LoS is required'),
  completedIn24HrsConfidence: yup.number().required('Confidence in 24hrs is required'),
  workOrderCreationTime: yup
    .string()
    .nullable(),
  identifiedByRoadUsers: yup
    .number()
    .min(0, 'Minimum value is 0')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  categories: yup.array().of(
    yup.object().shape({
      losTimeframe: yup
        .string()
        .nullable(),
      losTargetPercentage: yup
        .number()
        .min(0, 'Minimum value is 0')
        .max(100, 'Maximum value is 100')
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
      numberIdentified: yup
        .number()
        .min(0, 'Minimum value is 0')
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
      completedWithinLos: yup
        .number()
        .min(0, 'Minimum value is 0')
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
      completedWithin24Hrs: yup
        .number()
        .min(0, 'Minimum value is 0')
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
      notRepairedInQuarter: yup
        .number()
        .min(0, 'Minimum value is 0')
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
    }),
  ),
});

const formatAsTime = (value) => {
  if (!value) return '';
  let cleaned = value.replace(/\D/g, '');
  if (cleaned.length > 2) {
    cleaned = `${cleaned.slice(0, 2)}:${cleaned.slice(2, 4)}`;
  } else if (cleaned.length === 2 && !value.includes(':')) {
    cleaned = `${cleaned}:`;
  }
  return cleaned;
};

function PotholesResultForm({
  isOpen, onClose, selectedEntry, isTestMode,
}) {
  const {
    id, quadDatabaseId, quadLockYear, quarter, losAchievedConfidence, completedIn24HrsConfidence, workOrderCreationTime, categories, identifiedByRoadUsers, userIndicatedCompletion,
  } = selectedEntry;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      workOrderCreationTime: formatAsTime(workOrderCreationTime),
      losAchievedConfidence: losAchievedConfidence ? parseInt(losAchievedConfidence, 10) : 3,
      completedIn24HrsConfidence: completedIn24HrsConfidence ? parseInt(completedIn24HrsConfidence, 10) : 3,
      identifiedByRoadUsers: identifiedByRoadUsers || '',
      userIndicatedCompletion: userIndicatedCompletion || false,
      categories: categories?.map((category) => ({
        categoryId: category.id,
        losTimeframe: category.losTimeframe || '',
        losTargetPercentage: category.losTargetPercentage || '',
        numberIdentified: category.numberIdentified || '',
        completedWithinLos: category.completedWithinLos || '',
        completedWithin24Hrs: category.completedWithin24Hrs || '',
        notRepairedInQuarter: category.notRepairedInQuarter || '',
      })) || [],
    },
    resolver: yupResolver(schema),
  });

  const { mutateAsync: doCreatePotholesResult, isLoading: isCreating } = useCreatePotholesResult();
  const { mutateAsync: doUpdatePotholesResult, isLoading: isUpdating } = useUpdatePotholesResult();

  const modalTitle = id ? 'Update Potholes Result' : 'Add Potholes Result';
  const submitButtonLabel = id ? 'Update Result' : 'Save Result';

  // Reset form when selected entry changes
  useEffect(() => {
    reset({
      workOrderCreationTime: formatAsTime(workOrderCreationTime),
      losAchievedConfidence: losAchievedConfidence ? parseInt(losAchievedConfidence, 10) : 3,
      completedIn24HrsConfidence: completedIn24HrsConfidence ? parseInt(completedIn24HrsConfidence, 10) : 3,
      identifiedByRoadUsers: identifiedByRoadUsers || '',
      userIndicatedCompletion: userIndicatedCompletion || false,
      categories: [...categories],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntry]);

  const watchWorkOrderCreationTime = watch('workOrderCreationTime');

  // Format as time hh:mm when input changes
  const handleWorkOrderCreationTimeeChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatAsTime(value);
    setValue('workOrderCreationTime', formattedValue, { shouldValidate: true });
  };

  // Calculate totals for each category as they change
  const watchedCategories = watch('categories');
  const categoryTotals = watchedCategories?.reduce(
    (totals, category) => {
      totals.numberIdentified += category?.numberIdentified ? parseInt(category.numberIdentified, 10) : 0;
      totals.completedWithinLos += category?.completedWithinLos ? parseInt(category.completedWithinLos, 10) : 0;
      totals.completedWithin24Hrs += category?.completedWithin24Hrs ? parseInt(category.completedWithin24Hrs, 10) : 0;
      totals.notRepairedInQuarter += category?.notRepairedInQuarter ? parseInt(category.notRepairedInQuarter, 10) : 0;
      return totals;
    },
    {
      numberIdentified: 0,
      completedWithinLos: 0,
      completedWithin24Hrs: 0,
      notRepairedInQuarter: 0,
    },
  );

  // Calculate number identified by RCA/Contractors
  const watchNumberIdentifiedByRoadUsers = watch('identifiedByRoadUsers');
  const numberIdentifiedByRcaContractors = categoryTotals.numberIdentified - watchNumberIdentifiedByRoadUsers;

  const handleClose = () => {
    reset({
      losAchievedConfidence: 3,
      completedIn24HrsConfidence: 3,
      workOrderCreationTime: '',
      identifiedByRoadUsers: '',
      categories: [...categories],
    });
    onClose();
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      quarter,
      quadLockYear,
      quadDatabaseId,
    };

    // If testMode is on, tell the API to ignore date checks
    if (isTestMode) {
      formData.isTestMode = true;
    }

    if (id) {
      await doUpdatePotholesResult(formData);
    } else {
      await doCreatePotholesResult(formData);
    }

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <HStack mb={4} spacing={8}>
              <FormControl width="fit-content">
                <FormLabel mb={0}>Financial Year</FormLabel>
                <Text fontSize="xl">{quadLockYear}</Text>
              </FormControl>
              <FormControl width="fit-content">
                <FormLabel mb={0}>Entry Quarter</FormLabel>
                <Text fontSize="xl">
                  {QUARTERS.find((x) => x.id === quarter)?.label}
                </Text>
              </FormControl>
            </HStack>
            <Heading
              as="h3"
              fontSize="xl"
              color="brand.orange.500"
              mb={4}
            >
              Potholes Identified by Road Users
            </Heading>
            <HStack mb={4} spacing={8} justify="space-between">
              <FormControl
                isInvalid={!!errors.identifiedByRoadUsers}
                width="fit-content"
              >
                <FormLabel
                  whiteSpace="nowrap"
                >
                  No. Identified by Road Users
                </FormLabel>
                <Input
                  type="number"
                  {...register('identifiedByRoadUsers')}
                  width="90px"
                  onFocus={(e) => e.target.addEventListener('wheel', (event) => { event.preventDefault(); }, { passive: false })}
                />
                <FormErrorMessage>{errors.identifiedByRoadUsers && errors.identifiedByRoadUsers.message}</FormErrorMessage>
              </FormControl>
              <FormControl mb={4} isInvalid={!!errors.workOrderCreationTime}>
                <FormLabel>Average Time to Create Work Order</FormLabel>
                <HStack>
                  <Input
                    type="text"
                    width="90px"
                    {...register('workOrderCreationTime')}
                    onChange={handleWorkOrderCreationTimeeChange}
                    placeholder="hh:mm"
                  />
                  <Text fontSize="sm" color="gray.600">
                    {formatTimeAsHumanReadable(watchWorkOrderCreationTime)}
                  </Text>
                </HStack>
                <FormErrorMessage>{errors.workOrderCreationTime && errors.workOrderCreationTime.message}</FormErrorMessage>
              </FormControl>
            </HStack>
            <Box mb={4}>
              <Heading
                as="h3"
                fontSize="xl"
                color="brand.orange.500"
                mb={4}
              >
                All Identified Potholes by ONRC Categories
              </Heading>
              {categories?.map((category, index) => (
                <Box
                  key={category.categoryId}
                  mt={3}
                >
                  <Text fontSize="md" fontWeight="bold" mb={3}>
                    {category.categoryDescription}
                    <Text as="span" fontSize="sm" color="gray.600" pl={2} fontWeight="normal">
                      {category.sealedNetworkLength}
                      {' '}
                      km
                    </Text>
                  </Text>
                  <HStack spacing={4} mb={4}>
                    <HStack
                      p={3}
                      background="gray.50"
                      borderRadius="base"
                    >
                      <FormControl
                        isInvalid={!!errors.categories?.[index]?.numberIdentified}
                        width="fit-content"
                      >
                        <FormLabel
                          fontSize="xs"
                          whiteSpace="nowrap"
                        >
                          No. Identified
                        </FormLabel>
                        <Controller
                          name={`categories.${index}.numberIdentified`}
                          control={control}
                          render={({ field }) => (
                            <Input
                              type="number"
                              {...field}
                              width="90px"
                              onFocus={(e) => e.target.addEventListener('wheel', (event) => { event.preventDefault(); }, { passive: false })}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {errors.categories?.[index]?.numberIdentified?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </HStack>
                    <HStack
                      p={3}
                      background="gray.50"
                      borderRadius="base"
                      flexGrow="1"
                    >
                      <FormControl isInvalid={!!errors.categories?.[index]?.losTimeframe}>
                        <FormLabel fontSize="xs">LoS Timeframe</FormLabel>
                        <Controller
                          name={`categories.${index}.losTimeframe`}
                          control={control}
                          render={({ field }) => (
                            <Input type="text" {...field} />
                          )}
                        />
                        <FormErrorMessage>
                          {errors.categories?.[index]?.losTimeframe?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!errors.categories?.[index]?.losTargetPercentage} maxWidth="120px">
                        <FormLabel fontSize="xs">LoS Target %</FormLabel>
                        <Controller
                          name={`categories.${index}.losTargetPercentage`}
                          control={control}
                          render={({ field }) => (
                            <InputGroup>
                              <Input
                                type="number"
                                textAlign="right"
                                {...field}
                                onFocus={(e) => e.target.addEventListener('wheel', (event) => { event.preventDefault(); }, { passive: false })}
                              />
                              <InputRightAddon>%</InputRightAddon>
                            </InputGroup>
                          )}
                        />
                        <FormErrorMessage>
                          {errors.categories?.[index]?.losTargetPercentage?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={!!errors.categories?.[index]?.completedWithinLos}
                        width="fit-content"
                      >
                        <FormLabel
                          fontSize="xs"
                          whiteSpace="nowrap"
                        >
                          Repaired in LoS Timeframe
                        </FormLabel>
                        <Controller
                          name={`categories.${index}.completedWithinLos`}
                          control={control}
                          render={({ field }) => (
                            <HStack>
                              <Input
                                type="number"
                                {...field}
                                width="90px"
                                onFocus={(e) => e.target.addEventListener('wheel', (event) => { event.preventDefault(); }, { passive: false })}
                              />
                              <Box width="60px">
                                {calculatePercentage(watchedCategories[index]?.numberIdentified, watchedCategories[index]?.completedWithinLos)}
                              </Box>
                            </HStack>
                          )}
                        />
                        <FormErrorMessage>
                          {errors.categories?.[index]?.completedWithinLos?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </HStack>
                    <HStack
                      p={3}
                      background="gray.50"
                      borderRadius="base"
                    >
                      <FormControl
                        isInvalid={!!errors.categories?.[index]?.completedWithin24Hrs}
                        width="fit-content"
                      >
                        <FormLabel
                          fontSize="xs"
                          whiteSpace="nowrap"
                        >
                          Repaired in 24hrs
                        </FormLabel>
                        <Controller
                          name={`categories.${index}.completedWithin24Hrs`}
                          control={control}
                          render={({ field }) => (
                            <HStack>
                              <Input
                                type="number"
                                {...field}
                                width="90px"
                                onFocus={(e) => e.target.addEventListener('wheel', (event) => { event.preventDefault(); }, { passive: false })}
                              />
                              <Box width="60px">
                                {calculatePercentage(watchedCategories[index]?.numberIdentified, watchedCategories[index]?.completedWithin24Hrs)}
                              </Box>
                            </HStack>
                          )}
                        />
                        <FormErrorMessage>
                          {errors.categories?.[index]?.completedWithin24Hrs?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={!!errors.categories?.[index]?.notRepairedInQuarter}
                        width="fit-content"
                      >
                        <FormLabel
                          fontSize="xs"
                          whiteSpace="nowrap"
                        >
                          Not Repaired in the Quarter
                        </FormLabel>
                        <Controller
                          name={`categories.${index}.notRepairedInQuarter`}
                          control={control}
                          render={({ field }) => (
                            <HStack>
                              <Input
                                type="number"
                                {...field}
                                width="90px"
                                onFocus={(e) => e.target.addEventListener('wheel', (event) => { event.preventDefault(); }, { passive: false })}
                              />
                              <Box width="60px">
                                {calculatePercentage(watchedCategories[index]?.numberIdentified, watchedCategories[index]?.notRepairedInQuarter)}
                              </Box>
                            </HStack>
                          )}
                        />
                        <FormErrorMessage>
                          {errors.categories?.[index]?.notRepairedInQuarter?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </HStack>
                  </HStack>
                </Box>
              ))}
              <Box
                mt={4}
                py={4}
                borderTopColor="gray.100"
                borderTopWidth={2}
                borderBottomColor="gray.100"
                borderBottomWidth={2}
              >
                <Text fontSize="md" fontWeight="bold" mb={4}>
                  Totals
                </Text>
                <HStack gap={4} width="100%" align="flex-start" justify="space-between">
                  <VStack gap={0}>
                    <Text fontSize="xs" fontWeight="bold">Number Identified</Text>
                    <Text fontSize="4xl">{categoryTotals.numberIdentified}</Text>
                  </VStack>

                  <VStack gap={0}>
                    <Text fontSize="xs" fontWeight="bold">No. Identified by RCA/Contractors</Text>
                    <Text fontSize="4xl" color={numberIdentifiedByRcaContractors < 0 ? 'red.600' : 'inherit'}>{numberIdentifiedByRcaContractors}</Text>
                  </VStack>

                  <VStack gap={0}>
                    <Text fontSize="xs" fontWeight="bold">Repaired Within LOS</Text>
                    <HStack>
                      <Text fontSize="4xl">{categoryTotals.completedWithinLos}</Text>
                      {calculatePercentage(categoryTotals.numberIdentified, categoryTotals.completedWithinLos)}
                    </HStack>
                  </VStack>

                  <VStack gap={0}>
                    <Text fontSize="xs" fontWeight="bold">Repaired Within 24 Hours</Text>
                    <HStack>
                      <Text fontSize="4xl">{categoryTotals.completedWithin24Hrs}</Text>
                      {calculatePercentage(categoryTotals.numberIdentified, categoryTotals.completedWithin24Hrs)}
                    </HStack>
                  </VStack>

                  <VStack gap={0}>
                    <Text fontSize="xs" fontWeight="bold">Not Repaired in Quarter</Text>
                    <HStack>
                      <Text fontSize="4xl">{categoryTotals.notRepairedInQuarter}</Text>
                      {calculatePercentage(categoryTotals.numberIdentified, categoryTotals.notRepairedInQuarter)}
                    </HStack>
                  </VStack>
                </HStack>
              </Box>
            </Box>
            <FormControl isInvalid={!!errors.losAchievedConfidence} mb={4}>
              <FormLabel>Confidence in % Completed in LoS Timeframe</FormLabel>
              <Controller
                name="losAchievedConfidence"
                control={control}
                defaultValue={3}
                render={({ field }) => (
                  <VStack align="stretch" width="fit-content">
                    <ButtonGroup>
                      {Array.from({ length: 5 }, (_, i) => i + 1).map((value) => (
                        <Button
                          key={value}
                          variant={field.value === value ? 'solid' : 'outline'}
                          onClick={() => field.onChange(value)}
                          size="sm"
                        >
                          {value}
                        </Button>
                      ))}
                    </ButtonGroup>
                    <HStack justify="space-between" width="100%">
                      <Text fontSize="xs">Low</Text>
                      <Text fontSize="xs">High</Text>
                    </HStack>
                  </VStack>
                )}
              />
              <FormErrorMessage>{errors.losAchievedConfidence?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.completedIn24HrsConfidence} mb={4}>
              <FormLabel>Confidence in % Completed in 24hrs</FormLabel>
              <Controller
                name="completedIn24HrsConfidence"
                control={control}
                defaultValue={3}
                render={({ field }) => (
                  <VStack align="stretch" width="fit-content">
                    <ButtonGroup>
                      {Array.from({ length: 5 }, (_, i) => i + 1).map((value) => (
                        <Button
                          key={value}
                          variant={field.value === value ? 'solid' : 'outline'}
                          onClick={() => field.onChange(value)}
                          size="sm"
                        >
                          {value}
                        </Button>
                      ))}
                    </ButtonGroup>
                    <HStack justify="space-between" width="100%">
                      <Text fontSize="xs">Low</Text>
                      <Text fontSize="xs">High</Text>
                    </HStack>
                  </VStack>
                )}
              />
              <FormErrorMessage>{errors.completedIn24HrsConfidence?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Checkbox size="sm" {...register('userIndicatedCompletion')}>
              Tick if you have finished entering the Pothole results for the quarter
            </Checkbox>
            <HStack spacing={2} ml={4}>
              <Button colorScheme="gray" mr={3} onClick={handleClose}>
                Cancel
              </Button>
              <Button colorScheme="orange" type="submit" isLoading={isCreating || isUpdating}>
                {submitButtonLabel}
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default PotholesResultForm;
