import {
  Box,
  Button,
  Divider,
  Flex,
  HStack, Icon, VStack,
} from '@chakra-ui/react';
import useTceApi from '@src/modules/tce/src/api/useTceApi';
import {
  selectedJobIdState,
} from '@src/modules/tce/src/state/tce';
import Step1 from '@tce/components/pages/traffic-count-estimates/components/wizard/step1/Step1';
import Step2 from '@tce/components/pages/traffic-count-estimates/components/wizard/step2/Step2';
import Step3 from '@tce/components/pages/traffic-count-estimates/components/wizard/step3/Step3';
import Step4 from '@tce/components/pages/traffic-count-estimates/components/wizard/step4/Step4';
import Step5 from '@tce/components/pages/traffic-count-estimates/components/wizard/step5/Step5';
import { useJob } from '@tce/context/jobs';
import {
  Fragment,
  useEffect, useMemo, useState,
} from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useRecoilValue } from 'recoil';
import DownloadResultsButton from './DownloadResultsButton';
import { Step } from './Stepper';

export default function TceWizard() {
  const { job, refetch } = useJob();
  const api = useTceApi();
  const [currentStepIndex, setCurrentStepIndex] = useState(job?.parentJobId !== null ? 3 : 0);
  const jobId = useRecoilValue(selectedJobIdState);

  const [stepStatuses, setStepStatuses] = useState([
    null,
    null,
    null,
    null,
    null,
  ]);

  const changeStep = async (index) => {
    await refetch();
    setCurrentStepIndex(index);
  };

  const stepDefinitions = useMemo(
    () => [
      {
        label: 'Check Traffic Counts',
        errorText: 'All Counts must have a valid Traffic Mix',
        component: (
          <Step1 />
        ),
      },
      {
        label: 'Add Missing AADT Estimates',
        errorText: 'All carriageways must have an AADT estimate',
        component: (
          <Step2 />
        ),
      },
      {
        label: 'Assign Traffic Groups',
        errorText: 'All carriageways must have a Traffic Group',
        component: (
          <Step3 />
        ),
      },
      {
        label: 'Calculate Estimates',
        errorText: 'The calculation has not completed yet',
        component: (
          <Step4 canRunCalculation={stepStatuses.slice(0, 3).every((s) => s === 'complete')} onClick={() => changeStep(4)} />
        ),
      },
      {
        label: 'Results',
        errorText: 'Results have not been calculated yet',
        component: (
          <Step5 canSeeResults={job.status === 'RESULTS'} />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [job.status, stepStatuses],
  );

  const setStatus = (index, status) => {
    setStepStatuses((statuses) => [
      ...statuses.slice(0, index),
      status,
      ...statuses.slice(index + 1),
    ]);
  };

  const stepValidators = [
    async () => {
      if (stepStatuses[0] === 'complete') return;
      setStatus(0, 'loading');
      const isComplete = await api.validateStepOne(jobId);
      setStatus(0, isComplete ? 'complete' : 'error');
    },
    async () => {
      if (stepStatuses[1] === 'complete') return;
      setStatus(1, 'loading');
      const isComplete = await api.validateStepTwo(jobId);
      setStatus(1, isComplete ? 'complete' : 'error');
    },
    async () => {
      if (stepStatuses[2] === 'complete') return;
      setStatus(2, 'loading');
      const isComplete = await api.validateStepThree(jobId);
      setStatus(2, isComplete ? 'complete' : 'error');
    },
    () => {
      if (stepStatuses[3] === 'complete') return;
      const isCalculating = job.status === 'CALCULATING RATES';
      if (isCalculating) {
        setStatus(3, 'loading');
        return;
      }

      const isComplete = job.status === 'RESULTS';
      setStatus(3, isComplete ? 'complete' : 'error');
    },
    () => {
      if (stepStatuses[4] === 'complete') return;
      const isComplete = job.status === 'RESULTS';
      setStatus(4, isComplete ? 'complete' : 'error');
    },
  ];

  useEffect(() => {
    const validateSteps = async () => {
      const validatorsToRun = stepValidators.filter((_, index) => index <= currentStepIndex);
      await Promise.all(validatorsToRun.map((validator) => validator()));
    };
    validateSteps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex]);

  useEffect(() => {
    if (job.status === 'CALCULATING') {
      setStepStatuses(['complete', 'complete', 'complete', 'loading']);
      setCurrentStepIndex(3);
    } else if (job.status === 'RESULTS') {
      setStepStatuses(['complete', 'complete', 'complete', 'complete']);
      setCurrentStepIndex(4);
    }
  }, [job]);

  return (
    <Flex flexGrow="1" direction="column" align="center">
      <HStack
        align="stetch"
        w="full"
        px={10}
      >
        {stepDefinitions.map((step, index) => (
          <Fragment key={step.label}>
            <Step
              index={index}
              isActive={index === currentStepIndex}
              onClick={() => changeStep(index)}
              label={step.label}
              errorText={step.errorText}
              status={stepStatuses[index]}
            />
            {index < stepDefinitions.length - 1 && (
            <VStack w="full">
              <Box h="10px" />
              <Divider
                orientation="horizontal"
                borderWidth="2px"
              />
            </VStack>
            )}
          </Fragment>
        ))}
      </HStack>
      {stepDefinitions[currentStepIndex].component}
      <Flex width="100%" justify="space-between" mt="3">
        <Button
          isDisabled={currentStepIndex === 0}
          mr={4}
          onClick={() => changeStep(currentStepIndex - 1)}
          size="lg"
          variant="link"
          colorScheme="gray"
        >
          <Icon boxSize="8" as={FiChevronLeft} ml="2" />
          Previous Step
        </Button>
        <HStack>
          {currentStepIndex === stepDefinitions.length - 1 && job.status === 'RESULTS' && <DownloadResultsButton job={job} />}
          {currentStepIndex < stepDefinitions.length - 1 && (
          <Button
            size="lg"
            onClick={() => changeStep(currentStepIndex + 1)}
            variant="link"
            colorScheme="brand.blue"
          >
            Next Step
            <Icon boxSize="8" as={FiChevronRight} ml="2" />
          </Button>
          )}
        </HStack>
      </Flex>
    </Flex>
  );
}
