import {
  Flex,
} from '@chakra-ui/react';
import Filter from '@src/modules/tce/src/components/pages/traffic-count-estimates/components/wizard/Filter';
import Selection from '@src/modules/tce/src/components/pages/traffic-count-estimates/components/wizard/Selection';
import CheckAllHeaderComponent from '@tce/components/pages/traffic-count-estimates/components/wizard/step3/CheckAllHeaderComponent';
import { Step3Filter } from '@tce/components/pages/traffic-count-estimates/components/wizard/step3/Step3Filter';
import TrafficGroupAssignControls from '@tce/components/pages/traffic-count-estimates/components/wizard/step3/TrafficGroupAssignControls';
import {
  agTrafficMixColdef, dateFormatter,
} from '@tce/components/pages/traffic-count-estimates/components/wizard/table';
import TceGrid from '@tce/components/pages/traffic-count-estimates/components/wizard/TceGrid';
import { useRef, useState } from 'react';

const screenName = 'step3';

function DataTable() {
  const gridRef = useRef();
  const [selectAll, setSelectAll] = useState(false);
  const onChange = () => setSelectAll(!selectAll);
  const columnDefs = [
    {
      checkboxSelection: true,
      headerComponent: CheckAllHeaderComponent,
      headerComponentParams: { selectAll, onChange },
      width: 55,
      lockPosition: true,
      pinned: 'left',
      editable: false,
      cellStyle: {
        paddingLeft: 16,
      },
    },
    {
      editable: false,
      field: 'trafficGroup',
      headerName: 'Traffic Group',
    },
    { field: 'roadId', headerName: 'Road ID', width: 100 },
    { field: 'name', headerName: 'Road Name', width: 200 },
    { field: 'cwayNo', headerName: 'Cway Number', width: 100 },
    { field: 'cwayStart', headerName: 'Cway Start (m)', width: 100 },
    { field: 'cwayMid', headerName: 'Cway Mid (m)', width: 100 },
    { field: 'cwayEnd', headerName: 'Cway End (m)', width: 100 },
    { field: 'urbanRural', width: 70, headerName: 'Urban Rural' },
    { field: 'aadtEstimate', headerName: 'AADT Estimate', width: 100 },
    { field: 'latestCount', headerName: 'Latest Count', width: 100 },
    {
      field: 'latestCountDate', headerName: 'Latest Count Date', width: 100, valueFormatter: (params) => dateFormatter(params.value),
    },
    agTrafficMixColdef('pcCar', '% Car'),
    agTrafficMixColdef('pcLcv', '% LCV'),
    agTrafficMixColdef('pcMcv', '% MCV'),
    agTrafficMixColdef('pcHcvI', '% HCVI'),
    agTrafficMixColdef('pcHcvII', '% HCVII'),
    agTrafficMixColdef('pcBus', '% Buses'),
    agTrafficMixColdef('pcHeavy', '% Heavy'),
    { field: 'category', headerName: 'ONRC Category', width: 100 },
    { field: 'hierarchy', headerName: 'Cway Hierarchy' },
  ];
  return (
    <TceGrid
      columnDefs={columnDefs}
      isLoading={false}
      gridRef={gridRef}
      selectAll={selectAll}
      name={screenName}
    >
      <TrafficGroupAssignControls
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        name={screenName}
      />
    </TceGrid>
  );
}
export default function Step3() {
  return (
    <Flex direction="column" width="100%" flexGrow="1">
      <Filter name={screenName}>
        <Step3Filter name={screenName} />
      </Filter>
      <Selection />
      <DataTable />
    </Flex>
  );
}
