import {
  Flex,
  Text,
  LinkOverlay,
  Icon,
  LinkBox,
  SkeletonText,
  Skeleton,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  useDisclosure,
  Portal,
  PopoverAnchor,
  PopoverHeader,
} from '@chakra-ui/react';
import { FiArrowRight, FiInfo } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';
import LockYearBadge from '@src/modules/performance/components/LockYearBadge';

export default function Widget({
  link, title, description, lockYear, quarter, quarterFormat = 'month', isLoading, hideDescription = false, moreInfoContent = null, children, rest,
}) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="auto"
      zIndex="9999!important"
      isLazy
    >
      <PopoverAnchor>
        <Flex
          background="white"
          boxShadow="base"
          borderRadius="md"
          flexDir="column"
          justifyContent="space-between"
          height="100%"
          cursor={link ? 'pointer' : 'default'}
          borderWidth="2px"
          borderColor={isOpen ? 'brand.orange.400' : 'transparent'}
          transition="all 0.3s ease-in-out"
          _hover={{ borderColor: link ? 'brand.orange.400' : 'transparent' }}
          className="widget-container"
          {...rest}
          sx={{
            '@media print': {
              border: '1px solid #ddd',
              borderRadius: '4px',
              backgroundColor: '#fff',
              boxShadow: 'none',
            },
          }}
        >
          <LinkBox
            height="100%"
            display="grid"
            justify="space-between"
            flexDirection="column"
          >
            <Flex
              justify="space-between"
              align="flex-start"
              pt={3}
              px={3}
              flexGrow="1"
              className="widget-header"
              sx={{
                '@media print': {
                  padding: '4px 6px 0px 6px',
                },
              }}
            >
              {!title && (
              <SkeletonText
                noOfLines={2}
                isLoaded={!isLoading}
                width="60%"
                align="center"
                height={12}
              />
              )}
              {title && !title.includes('null') && !title.includes('undefined')
                && (
                <Text
                  whiteSpace="pre-line"
                  textAlign="left"
                  fontWeight="bold"
                  color="gray.700"
                  fontSize={13}
                  lineHeight={1.1}
                >
                  {title.includes('\n') ? title : `${title}\n \xA0`}
                </Text>
                )}
              {moreInfoContent
              && (
              <PopoverTrigger>
                <IconButton
                  variant="ghost"
                  colorScheme="blue"
                  icon={<Icon as={FiInfo} boxSize={4} />}
                  aria-label="info"
                  size="xs"
                  mt={-1}
                  mr={-1}
                  zIndex="10!important"
                  _focus={{ boxShadow: 'none' }}
                  className="hidden-print"
                />
              </PopoverTrigger>
              )}
            </Flex>
            <Flex
              align="center"
              justify="center"
              width="100%"
              minHeight="140px"
              className="widget-body"
            >
              {children}
            </Flex>
            <Flex
              flexGrow="1"
              direction="column"
              justify="space-between"
              alignSelf="flex-end"
            >
              {!hideDescription && (
              <Flex
                flexGrow="1"
                justify="flex-start"
                align="center"
                flexDir="column"
                px={2}
                className="widget-description"
              >
                {(!description || isLoading) && (
                  <SkeletonText
                    noOfLines={2}
                    isLoaded={!isLoading}
                    width="60%"
                    align="center"
                    height={12}
                  />
                )}
                {description && !description.includes('null') && !description.includes('undefined') && !isLoading
                  && (
                  <Text
                    textAlign="center"
                    fontSize="xs"
                    whiteSpace="pre-line"
                    color="gray.600"
                    lineHeight={1.2}
                  >
                    {description.includes('\n') ? description : `${description}\n \xA0`}
                  </Text>
                  )}
              </Flex>
              )}
              <Flex
                justify="space-between"
                align="center"
                p={3}
                className="widget-footer"
                sx={{
                  '@media print': {
                    padding: '3px 6px 6px 6px',
                  },
                }}
              >
                {isLoading && <Skeleton width="30%" borderRadius="full" height={6} />}
                {!isLoading && <LockYearBadge lockYear={lockYear} quarter={quarter} quarterFormat={quarterFormat} />}
                {link && !isLoading && (
                  <LinkOverlay as={RouterLink} to={link} display="inline-flex" className="hidden-print"><Icon as={FiArrowRight} boxSize={6} /></LinkOverlay>
                )}
              </Flex>
            </Flex>
          </LinkBox>
        </Flex>
      </PopoverAnchor>
      {moreInfoContent && (
      <Portal zIndex="9999!important">
        <PopoverContent zIndex="9999!important" _focus={{ boxShadow: 'lg' }}>
          <PopoverArrow />
          <PopoverHeader>More Information</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            {moreInfoContent}
          </PopoverBody>
        </PopoverContent>
      </Portal>
      )}
    </Popover>
  );
}
