import { selectedDatabaseIdState } from '@transport-insights/uikit';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { useToast } from '@chakra-ui/react';
import useTemporaryTrafficManagementApi from '../api/useTemporaryTrafficManagementApi';

export function useCurrentTtmEntryPeriod() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const api = useTemporaryTrafficManagementApi();

  return useQuery(
    ['temporary-traffic-management-entry-period', rcaId],
    async () => api.getCurrentTtmEntryPeriod(rcaId),
  );
}

export function useTtmList() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const api = useTemporaryTrafficManagementApi();

  return useQuery(
    ['temporary-traffic-management', rcaId],
    async () => api.getTtmList(rcaId),
  );
}

export function useTemporaryTrafficManagementKpi() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const api = useTemporaryTrafficManagementApi();
  // Refactor data to convert value to a percentage
  return useQuery(
    ['temporary-traffic-management-kpi', rcaId],
    async () => api.getTemporaryTrafficManagementKpi(rcaId),
    {
      select: (data) => ({ ...data, value: data.value }),
    },
  );
}

export const useCreateTtmResult = () => {
  const api = useTemporaryTrafficManagementApi();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    (ttmResult) => api.createTtmResult(ttmResult).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('temporary-traffic-management');
        queryClient.invalidateQueries('temporary-traffic-management-kpi');
        toast({
          title: 'Success',
          description: 'Result was successfully created.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.response.data.Message ? error.response.data.Message : 'Result could not be created. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );
};

export const useUpdateTtmResult = () => {
  const api = useTemporaryTrafficManagementApi();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    (ttmResult) => api.updateTtmResult(ttmResult).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('temporary-traffic-management');
        queryClient.invalidateQueries('temporary-traffic-management-kpi');
        toast({
          title: 'Success',
          description: 'Result was successfully updated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.response.data.Message ? error.response.data.Message : 'Result could not be updated. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );
};

export const useCreateTtmSiteInspection = () => {
  const api = useTemporaryTrafficManagementApi();
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation(
    (ttmSiteInspection) => api.createTtmSiteInspection({ ...ttmSiteInspection, quadDatabaseId: rcaId }).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('temporary-traffic-management-site-inspections');
        toast({
          title: 'Success',
          description: 'Site inspection was successfully created.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.response.data.Message ? error.response.data.Message : 'Site inspection could not be created. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );
};

export function useGetTtmSiteInspectionList({ startDate, endDate }) {
  const api = useTemporaryTrafficManagementApi();
  const rcaId = useRecoilValue(selectedDatabaseIdState);

  return useQuery(
    ['temporary-traffic-management-site-inspections', rcaId, startDate, endDate],
    async () => api.getTtmSiteInspectionList({ rcaId, startDate, endDate }),
    {
      cacheTime: 3600,
      enabled: rcaId !== undefined && startDate !== undefined && endDate !== undefined,
    },
  );
}

export function useTemporaryTrafficManagementCostPercentTrend() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const api = useTemporaryTrafficManagementApi();
  return useQuery(
    ['temporary-traffic-management-cost-percent-trend', rcaId],
    async () => api.getTemporaryTrafficManagementCostPercentTrend(rcaId),
    {
      enabled: rcaId !== undefined,
      select: (data) => (data.sort((a, b) => {
        const yearComparison = a.lockYear.localeCompare(b.lockYear);
        return yearComparison !== 0 ? yearComparison : a.quarter - b.quarter;
      })),
    },
  );
}

export function useTemporaryTrafficManagementCostConfidence() {
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const api = useTemporaryTrafficManagementApi();
  return useQuery(
    ['temporary-traffic-management-cost-confidence', rcaId],
    async () => api.getTemporaryTrafficManagementCostConfidence(rcaId),
    {
      enabled: rcaId !== undefined,
      select: (data) => ({
        ...data,
        costConfidences: data.costConfidences.sort((a, b) => {
          const yearComparison = a.lockYear.localeCompare(b.lockYear);
          return yearComparison !== 0 ? yearComparison : a.quarter - b.quarter;
        }),
        nationalPercentiles: data.nationalPercentiles.sort((a, b) => a.quarter - b.quarter),
      }),
    },
  );
}
