import {
  Box,
  useTheme,
  Grid,
  GridItem,
  SimpleGrid,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import { groupBy } from 'lodash';

import { getContrastColor, COMPARISON_COLORS, getTrendlineColor } from '@src/utils/color';
import {
  getRangeOfPoints, convertBubbleRange, roundToDecimal,
} from '@src/utils/math';
import ChartContainer from '../shared/ChartContainer';
import ReportSubheading from '../shared/ReportSubheading';
import { categories } from '../../api/categories';
import CollapsedContent from '../shared/CollapsedContent';

export default function Ia2({
  filters, selectedToggleOption, reportData, networkSelector,
}) {
  const theme = useTheme();
  const filteredCategories = categories.filter((x) => filters.categories.indexOf(x.description) > -1);
  const years = [...new Set(reportData.data.map((x) => x.latestYear))].sort();
  const rca = reportData.rcas[0];
  const toggleText = selectedToggleOption?.report_type?.[0];
  const filteredReportData = reportData.data.filter((x) => Object.entries(selectedToggleOption).every(([k, v]) => v.includes(x[k])));
  const selectedRcaData = filteredReportData.filter((x) => x.id === rca.id);
  const currentYearData = filteredReportData.filter((x) => x.latestYear === years.at(-1));
  const currentYearOverview = selectedRcaData.filter((x) => x.latestYear === years.at(-1))
    .filter((x) => x.perc > 0)
    .map((item) => ({
      category: item.category,
      perc: item.perc,
      perc85: item.perc85,
      median: item.perc50,
      average: item.naasra_avg,
      vkt: roundToDecimal(item.vkt, 1),
      length: roundToDecimal(item.length_km, 1),
      data: [item.perc05, item.perc25, item.perc50, item.perc75, item.perc95],
    }));
  const bubbleChart = selectedRcaData.filter((x) => x.latestYear === years.at(-1))
    .filter((x) => x.perc > 0)
    .map((item) => ({
      category: item.category,
      count: item.perc,
      vkt: roundToDecimal(item.vkt, 1),
      length: roundToDecimal(item.length_km, 1),
    }));
  const transformedDataByRca = Object.entries(groupBy(currentYearData, 'rcaName'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        x: item.category,
        y: item.perc.toFixed(2),
      })),
    }));
  const transformedDataByCategory = Object.entries(groupBy(selectedRcaData, 'category'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        year: item.latestYear,
        value: item.perc,
        trendY1: item.percB,
        trendY2: item.percB + (item.percM * item.percCount),
      })),
    }));
  const summaryData = Object.entries(groupBy(filteredReportData, 'category'))
    .map(([k, v]) => ({
      category: k,
      data: v.map((item) => ({
        year: item.latestYear,
        rcaName: item.rcaName,
        perc05: item.perc05,
        perc25: item.perc25,
        perc50: item.perc50,
        perc75: item.perc75,
        perc85: item.perc85,
        perc95: item.perc95,
        median: item.perc50,
        average: item.naasra_avg,
        perc: item.perc,
      })),
    }))
    .map((x) => ({
      name: x.category,
      data: Object.entries(groupBy(x.data, 'rcaName'))
        .map(([k, v]) => ({
          rcaName: k,
          data: v.map((item) => ({
            year: item.year,
            perc05: item.perc05,
            perc25: item.perc25,
            perc50: item.perc50,
            perc75: item.perc75,
            perc85: item.perc85,
            perc95: item.perc95,
            median: item.median,
            average: item.average,
            perc: item.perc,
          })),
        })),
    }));
  return (
    <>
      <Grid templateColumns={{ md: '100%', lg: '1fr 1fr', xl: '2fr 1fr' }} gap={4}>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>
            Peak Roughness for each ONF Street Category
          </ReportSubheading>
          <Text mb={8}>
            This chart shows the spread of Peak Roughness of roads in the network, by ONF Street Category.  The higher the roughness number, the rougher the ride. The lower whisker shows the peak roughness for the top 5% to 25% of the smoothest roads in the Street Category. The upper whisker shows the peak roughness for the top 75% to 95% smoothest roads in the Street Category. The box shows the peak roughness for 25% to 75% including a line for the 50th percentile. Hover on the graph to see the roughness values. Toggle between the 75th, 85th and 95th percentile to see the roughness count per Street Category.
          </Text>
          <ChartContainer>
            <Chart
              options={
                {
                  xaxis: {
                    type: 'category',
                    categories: [...new Set(currentYearOverview.map((x) => x.category))],
                    labels: {
                      style: {
                        colors: currentYearOverview.map((x) => theme.colors.onfCategory[x.category].base),
                        fontWeight: 'bold',
                      },
                    },
                    tickPlacement: 'between',
                  },
                  yaxis: [
                    {
                      forceNiceScale: true,
                      title: {
                        text: `NAASRA ${toggleText}th Percentile`,
                      },
                      labels: {
                        formatter(val) {
                          return `${val.toFixed(0)}`;
                        },
                      },
                    },
                  ],
                  dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1, 2, 3, 4],
                    offsetX: -5,
                    textAnchor: 'end',
                    distributed: true,
                  },
                  colors: currentYearOverview.map((x) => theme.colors.onfCategory[x.category].base),
                  legend: {
                    show: true,
                    customLegendItems: [`NAASRA ${toggleText}th Percentile`],
                  },
                  toolbar: {
                    export: {
                      csv: {
                        headerCategory: 'ONF Category;5%;25%;50%;75%;95%;85%',
                        columnDelimiter: ';',
                      },
                    },
                  },
                  tooltip: {
                    enabled: true,
                    enabledOnSeries: [0],
                    marker: {
                      show: false,
                    },
                    custom({ seriesIndex, dataPointIndex, w }) {
                      const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

                      return `<div style="padding: 10px">
                      <p style="color: ${theme.colors.onfCategory[data.x].base}; font-weight: bold; margin-bottom: 10px;">${data.x}</p> 
                      <ul>
                      <li><b>NAASRA ${toggleText}th Percentile</b>: ${data.percentile}</li>
                      <li><b>95th Percentile</b>: ${data.y[4]}</li>
                      <li><b>85th Percentile</b>: ${data.perc85}</li>
                      <li><b>75th Percentile</b>: ${data.y[3]}</li>
                      <li><b>Average</b>: ${data.average}</li>
                      <li><b>Median</b>: ${data.y[2]}</li>
                      <li><b>25th Percentile</b>: ${data.y[1]}</li>
                      <li><b>5th Percentile</b>: ${data.y[0]}</li>
                      </ul></div>`;
                    },
                  },
                  plotOptions: {
                    boxPlot: {
                      colors: {
                        upper: theme.colors.gray[50],
                        lower: theme.colors.gray[50],
                      },
                    },
                  },
                }
              }
              series={
                [
                  {
                    type: 'boxPlot',
                    name: 'NAASRA Range',
                    data: currentYearOverview.map((x) => ({
                      y: x.data, x: x.category, percentile: x.perc, median: x.median, average: x.average, perc85: x.perc85,
                    })),
                  },
                  {
                    type: 'scatter',
                    name: `NAASRA ${toggleText}th Percentile`,
                    data: currentYearOverview.map((x) => ({
                      y: x.perc, x: x.category, fillColor: theme.colors.onfCategory[x.category].base,
                    })),
                  },
                ]
              }
              type="boxPlot"
              width="100%"
              height="400"
            />
          </ChartContainer>
        </GridItem>
        <GridItem display="flex" flexDirection="column">
          <ReportSubheading>VKT % per ONF Street Category</ReportSubheading>
          <Text mb={8}>This chart shows the percentage of VKT per ONF Street Category, in the network.</Text>
          <ChartContainer>
            <Chart
              options={
                {
                  dataLabels: {
                    enabled: true,
                  },
                  colors: currentYearOverview.map((x) => theme.colors.onfCategory[x.category].base),
                  legend: {
                    show: true,
                    position: 'bottom',
                  },
                  tooltip: {
                    enabled: false,
                    enabledOnSeries: [0],
                    marker: {
                      show: false,
                    },
                  },
                  labels: currentYearOverview.map((x) => x.category),
                }
              }
              series={currentYearOverview.map((x) => x.vkt)}
              type="pie"
              width="100%"
            />
          </ChartContainer>
        </GridItem>
        <GridItem display="flex" flexDirection="column" />
      </Grid>
      <Box my={8}>
        <ReportSubheading>Peak Roughness compared to VKT and Network Length</ReportSubheading>
        <Text mb={8}>
          This chart shows the peak NAASRA Roughness Count at the chosen percentile (75, 85 or 95) for each ONF Street Category. The higher the bubble, the rougher the ride on the worst roads in that category. The size of the bubble indicates the vehicle journeys (VKT) for each category. The bottom axis is the network length of the Street Categories.
        </Text>
        <ChartContainer minHeight="450">
          <Chart
            options={
              {
                colors: bubbleChart.map((x) => theme.colors.onfCategory[x.category].base),
                grid: {
                  padding: {
                    left: 20,
                    bottom: 50,
                  },
                },
                legend: {
                  offsetY: -20,
                },
                xaxis: {
                  type: 'numeric',
                  title: {
                    text: 'Street Category Network Length (km)',
                    offsetY: 10,
                  },
                  tickAmount: 10,
                  min: -5,
                  max: Math.round(Math.max(...bubbleChart.map((x) => x.length)) * 1.1),
                  labels: {
                    formatter(val) {
                      if (val < 0) return '0 km';
                      return `${val.toFixed(0)} km`;
                    },
                  },
                },
                yaxis: [
                  {
                    forceNiceScale: true,
                    max: (max) => Math.round((max + 0.5) * 1.2),
                    title: {
                      text: `NAASRA ${toggleText}th Percentile`,
                    },
                    labels: {
                      formatter(val) {
                        return `${val.toFixed(1)}`;
                      },
                    },
                  },
                ],
                dataLabels: {
                  enabled: false,
                },
                fill: {
                  opacity: 0.8,
                },
                tooltip: {
                  marker: {
                    show: false,
                  },
                  z: {
                    title: 'VKT:',
                  },
                  y: {
                    title: {
                      formatter: () => 'Percentage:',
                    },
                  },
                  x: {
                    show: true,
                    formatter: (val, { seriesIndex, w }) => `${w.globals.seriesNames[seriesIndex]}: ${val.toFixed(0)} km`,
                  },
                  custom({ seriesIndex, dataPointIndex, w }) {
                    const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                    const { name } = w.globals.initialSeries[seriesIndex];

                    return `<div style="padding: 10px">
                    <p style="color: ${theme.colors.onfCategory[name].base}; font-weight: bold; margin-bottom: 10px;">${name}</p> 
                    <ul>
                    <li><b>Peak Roughness</b>: ${data.y}</li>
                    <li><b>Length</b>: ${data.x} km</li>
                    <li><b>VKT</b>: ${data.vkt} mil</li>
                    </ul></div>`;
                  },
                },
                annotations: {
                  position: 'back',
                  yaxis: [
                    {
                      x: 0,
                      strokeDashArray: 0,
                      borderColor: '#666',
                      borderWidth: 1,
                      opacity: 0.5,
                    },
                  ],
                },
                plotOptions: {
                  bubble: {
                    zScaling: true,
                    minBubbleRadius: 4,
                    maxBubbleRadius: 100,
                  },
                },
              }
            }
            series={bubbleChart.map((item) => ({
              name: item.category,
              data: [
                {
                  x: item.length,
                  y: item.count,
                  z: convertBubbleRange(item.vkt, [Math.min(...bubbleChart.map((x) => x.vkt)), Math.max(...bubbleChart.map((x) => x.vkt))], [1, 5]),
                  vkt: item.vkt,
                }],
            }))}
            type="bubble"
            width="100%"
            height="100%"
          />
        </ChartContainer>
      </Box>
      <Box my={8}>
        <ReportSubheading>Peak Roughness trend per ONF Category</ReportSubheading>
        <Text mb={8}>
          These graphs show the annual change of peak roughness on the roads in each ONF Street Category for the chosen percentile and the linear trend (green is improving, red is declining).
        </Text>
        <SimpleGrid columns={[2, 2, 4, 4, 6, 6]} spacing={2}>
          {transformedDataByCategory.map((cat) => (
            <GridItem
              as={ChartContainer}
              key={`cat_${cat.name}`}
              p={2}
              minH="280"
            >
              <Chart
                options={
                  {
                    title: {
                      text: cat.name,
                      style: {
                        color: theme.colors.onfCategory[cat.name].base,
                      },
                    },
                    xaxis: {
                      categories: years,
                    },
                    yaxis: [
                      {
                        forceNiceScale: true,
                        min: 0,
                        max: Math.max(...transformedDataByCategory.flatMap((x) => x.data.map((y) => y.value))),
                        title: 'NAASRA',
                        labels: {
                          formatter(val) {
                            return `${val.toFixed(0)}`;
                          },
                        },
                      },
                    ],
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: 'straight',
                      width: [0, 4],
                    },
                    colors: [getTrendlineColor(cat.data.at(-1).trendY1, cat.data.at(-1).trendY2, false)],
                    fill: {
                      colors: [theme.colors.onfCategory[cat.name].base],
                    },
                    markers: {
                      size: 0,
                      hover: {
                        size: 0,
                      },
                    },
                    legend: {
                      show: false,
                    },
                    tooltip: {
                      enabled: true,
                      enabledOnSeries: [0],
                      marker: {
                        show: false,
                      },
                    },
                  }
                }
                series={
                  [
                    {
                      name: cat.name,
                      type: 'column',
                      data: cat.data.map((x) => x.value),
                    },
                    {
                      name: 'Trend',
                      type: 'line',
                      data: getRangeOfPoints(cat.data.at(-1).trendY1, cat.data.at(-1).trendY2, years.length),
                    },
                  ]
                }
                type="line"
                width="100%"
                height="100%"
              />
            </GridItem>
          ))}
        </SimpleGrid>
      </Box>
      {networkSelector}
      <Box my={8}>
        <ReportSubheading>Peak Roughness trends compared to other networks</ReportSubheading>
        <Text mb={8}>
          These graphs show the annual change of peak roughness on the roads in each ONF Street Category for the chosen percentile, compared to the trends for the other networks you are comparing with (defaults to the Peer Group and Region of the RCA, and National).
        </Text>
        <SimpleGrid columns={[2, 2, 4, 4, 6, 6]} spacing={2}>
          {summaryData.map((cat) => (
            <GridItem
              as={ChartContainer}
              key={`cat_${cat.name}`}
              p={2}
              minH="340"
            >
              <Chart
                options={
                  {
                    title: {
                      text: cat.name,
                      style: {
                        color: theme.colors.onfCategory[cat.name].base,
                      },
                    },
                    colors: [rca.rcaBrandColour, ...COMPARISON_COLORS],
                    xaxis: {
                      categories: years,
                    },
                    yaxis: [
                      {
                        forceNiceScale: true,
                        min: 0,
                        max: Math.max(...summaryData.flatMap((x) => x.data.flatMap((y) => y.data.map((z) => z.perc)))),
                        title: 'NAASRA',
                        labels: {
                          formatter(val) {
                            return `${val.toFixed(0)}`;
                          },
                        },
                      },
                    ],
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: 'straight',
                      width: 2,
                    },
                    markers: {
                      size: 0,
                      hover: {
                        size: 0,
                      },
                    },
                    legend: {
                      show: true,
                      markers: {
                        width: 12,
                        height: 2,
                        strokeWidth: 0,
                        radius: 0,
                      },
                    },
                    tooltip: {
                      enabled: true,
                      marker: {
                        show: false,
                      },
                    },
                  }
                }
                series={
                  cat.data.map(({ rcaName, data }) => ({
                    name: rcaName,
                    data: data.map(({ year, perc }) => ({ x: year, y: perc })),
                  }))
                }
                type="line"
                width="100%"
                height="100%"
              />
            </GridItem>
          ))}
        </SimpleGrid>
      </Box>
      <Box my={8}>
        <ReportSubheading>
          Peak Roughness compared to other networks
        </ReportSubheading>
        <Text mb={8}>
          This graph shows the peak roughness on the roads in each ONF Street Category for the chosen percentile, compared to the peak roughness for the other networks you are comparing with (defaults to the Peer Group and Region of the RCA, and National).
        </Text>
        <ChartContainer>
          <Chart
            options={
              {
                stroke: {
                  colors: ['transparent'],
                  width: 1,
                },
                colors: [rca.rcaBrandColour, ...COMPARISON_COLORS],
                yaxis: [
                  {
                    forceNiceScale: true,
                    tickAmount: 8,
                    labels: {
                      formatter(val) {
                        return `${val ? val.toFixed(0) : val}`;
                      },
                    },
                    title: {
                      text: `NAASRA ${toggleText}th Percentile`,
                    },
                  },
                ],
                xaxis: {
                  categories: filteredCategories.map((cat) => cat.description),
                  labels: {
                    style: {
                      colors: filteredCategories.map((cat) => theme.colors.onfCategory[cat.description].base),
                      fontWeight: 'bold',
                    },
                  },
                  tickPlacement: 'between',
                },
                tooltip: {
                  intersect: false,
                  shared: true,
                  followCursor: true,
                  marker: {
                    show: true,
                  },
                },
                dataLabels: {
                  enabled: false,
                  formatter(val) {
                    return val || 0;
                  },
                },
                legend: {
                  onItemClick: {
                    toggleDataSeries: false,
                  },
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: true,
                    },
                  },
                },
              }
            }
            series={transformedDataByRca}
            type="bar"
            width="100%"
            height="500"
          />
        </ChartContainer>
      </Box>
      <CollapsedContent collapsedLabel="View Data Table" openLabel="Hide Data Table">
        <Box border="1px solid" borderColor="gray.100" my={4}>
          <TableContainer>
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th position="sticky" left="0" background="gray.50" py={4} whiteSpace="nowrap" width="1px">
                    Category
                  </Th>
                  <Th background="gray.50">Network</Th>
                  <Th background="gray.50">Year</Th>
                  <Th background="gray.50">5%</Th>
                  <Th background="gray.50">25%</Th>
                  <Th background="gray.50">Median</Th>
                  <Th background="gray.50">Average</Th>
                  <Th background="gray.50">75%</Th>
                  <Th background="gray.50">85%</Th>
                  <Th background="gray.50">95%</Th>
                </Tr>
              </Thead>
              <Tbody>
                {summaryData.map((category, idx1) => category.data.map(({ rcaName, data }, idx2) => data.map(({
                  year, perc05, perc25, median, perc75, perc85, perc95, average,
                }, idx3) => (
                  <Tr key={`row_${idx1}_${idx2}_${idx3}`}>
                    {idx2 === 0 && idx3 === 0
                      && (
                      <Td
                        position="sticky"
                        left="0"
                        color={getContrastColor(theme.colors.onfCategory[category.name].base)}
                        background={theme.colors.onfCategory[category.name].base}
                        rowSpan={category.data.length * years.length}
                        borderBottom="2px solid"
                        borderColor={idx2 === 0 ? theme.colors.onfCategory[category.name].base : 'gray.100'}
                        fontWeight="bold"
                      >
                        {category.name}
                      </Td>
                      )}
                    {idx3 === 0
                      && (
                      <Td
                        rowSpan={years.length}
                        borderBottom="2px solid"
                        borderColor="gray.100"
                      >
                        {rcaName}
                      </Td>
                      )}
                    <Td
                      key={`cat_${idx3}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {year}
                    </Td>
                    <Td
                      key={`cat_${idx3}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {perc05}
                    </Td>
                    <Td
                      key={`cat_${idx3}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {perc25}
                    </Td>
                    <Td
                      key={`cat_${idx3}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {median}
                    </Td>
                    <Td
                      key={`cat_${idx3}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {average}
                    </Td>
                    <Td
                      key={`cat_${idx3}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {perc75}
                    </Td>
                    <Td
                      key={`cat_${idx3}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {perc85}
                    </Td>
                    <Td
                      key={`cat_${idx3}_${Math.random()}`}
                      borderBottom="2px solid"
                      borderColor="gray.100"
                    >
                      {perc95}
                    </Td>
                  </Tr>
                ))))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </CollapsedContent>
    </>
  );
}
