import { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Icon,
  Textarea,
  Tooltip,
  HStack,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { FiMessageCircle } from 'react-icons/fi';
import { useCreateJobNote, useFetchJobNotes } from '@src/modules/tce/src/context/jobs';
import useTceApi from '@src/modules/tce/src/api/useTceApi';

function NoteModal({ imp }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [note, setNote] = useState('');
  const { data, isLoading } = useFetchJobNotes(imp.job?.id);
  const { mutateAsync: doCreateNote, isLoading: isCreating } = useCreateJobNote();
  const [isDownloadingChangeLog, setIsDownloadingChangeLog] = useState(false);
  const api = useTceApi();
  const toast = useToast();

  const handleSave = async () => {
    await doCreateNote({ jobId: imp.job?.id, userNotes: note });
    onClose();
  };

  const handleCancel = () => {
    setNote('');
    onClose();
  };

  const handleDownloadChangeLog = async () => {
    try {
      setIsDownloadingChangeLog(true);
      await api.downloadModificationsChangeLog(imp.job?.id)
        .then((res) => {
          const fileName = `TCE_Change_Log_${imp.lockYear}_${imp.id}.csv`;
          const url = URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(url);
        });
    } catch (error) {
      console.log(error);
      toast({
        title: 'Error',
        description: 'Failed to download change log',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setIsDownloadingChangeLog(false);
  };

  useEffect(() => {
    if (data?.userNotes) {
      setNote(data.userNotes);
    }
  }, [data, isOpen]);

  if (isLoading) {
    return <Spinner size="sm" />;
  }

  return (
    <>
      <Tooltip label="Notes" aria-label="Notes">
        <IconButton
          onClick={onOpen}
          colorScheme="gray"
          variant="ghost"
          size="sm"
          icon={<Icon as={FiMessageCircle} boxSize={5} />}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Notes for
            {' '}
            {`${imp.lockYear} #${imp.id}`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Enter your note..."
              rows="5"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              onClick={handleDownloadChangeLog}
              size="sm"
              colorScheme="gray"
              variant="ghost"
              isLoading={isDownloadingChangeLog}
            >
              Download Change Log
            </Button>
            <HStack spacing={2}>
              <Button onClick={handleCancel} colorScheme="gray">
                Cancel
              </Button>
              <Button onClick={handleSave} isLoading={isCreating}>
                Save Note
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default NoteModal;
